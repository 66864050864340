import {ApiConfig} from './ApiConfig';

export const ApiStageBuilder = async (options) => {
    const apiHeader = {
        method:'POST',
        headers : {
            'Content-Type':'application/json'
        },
        body : options.body
    }
    const responseRequest = await fetch(ApiConfig()+'/'+options.method, apiHeader);
    if(!responseRequest.ok){
        throw new Error();
    }
    return await responseRequest.json();
}