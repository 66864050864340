import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {GithubPicker} from 'react-color';

class ColorBar extends React.Component{
   constructor(props){
        super(props);
        
        this.handleSelectedChangeColorElement = this.handleSelectedChangeColorElement.bind(this);
    }
    handleSelectedChangeColorElement(color){
        return this.props.handleSelectedChangeColorElement(color.hex);
    }
    render(){
        return(
            <Row className="shape-container">
                <Col>
                    <GithubPicker id="color-picker"
                        color={this.props.actualLineColorValue}
                        onChange={this.handleSelectedChangeColorElement}
                    />
                </Col>
            </Row>
        );
    }
}
export default ColorBar;