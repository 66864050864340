import React from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import ListStage from './ListStage';
import Modal from '@material-ui/core/Modal';
import SnackBarMessage from './SnackBarMessage';
import {ReactComponent as SaveStageDarkIcon} from './icons/SaveStageDarkIcon.svg';

class ModalSaveStage extends React.Component{
    constructor(props){
        super(props);
        
        this.handleSaveStage = this.handleSaveStage.bind(this);
        this.handleSendStage = this.handleSendStage.bind(this);

        this.state = {
            messageSnackBarStageSave : '',
            nameStageValue : '',
            showSnackBarStageSave : false,
            successSnackBarStageSave : false
        };

        this.refInputSaveStage = React.createRef();
    }
    handleSaveStage(){
        const currentValue = this.refInputSaveStage.current.value;
        if(currentValue){
            this.setState({
                nameStageValue : currentValue
            })
            return this.props.handleSaveStage(currentValue);
        }
    }
    handleSendStage(){
        return this.props.handleSendStage(this.state.nameStageValue);
    }
    render(){
        return(
            <>
                <Modal className="modal-params-backdrop"
                    open={this.props.showModalSaveStage}
                    onClose={this.props.handleSetCloseModalSaveStage}
                >
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-params w-100">
                        <div className="modal-content modal-content-scroll">
                            <div className="params-header modal-header">
                                <div className="modal-title h4">
                                    <SaveStageDarkIcon />
                                    <br />
                                    SAVE STAGE
                                </div>
                                <button type="button" className="close" onClick={this.props.handleSetCloseModalSaveStage}>
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            {this.props.showDuplicateStage === false 
                                ?
                                <>
                                    <div className="modal-sub-title">
                                        Save your stage, you can back to it later
                                    </div>
                                    <Container className="w-50 mt-3">
                                        <Row>
                                            <Col lg={3} md={3} className="align-items-center d-flex p-0 label-params">
                                                STAGE NAME
                                            </Col>
                                            <Col lg={6} md={6} className="pr-1 pl-1">
                                                <Form.Control type="text" className="input-form-params" ref={ref => this.refInputSaveStage.current = ref}/>
                                            </Col>
                                            <Col lg={3} md={3} className="pr-1 pl-1">
                                                <Button variant="danger" className="modal-button-save w-100" onClick={this.handleSaveStage}>
                                                    SAVE
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </>
                                :
                                <>
                                    <div className="modal-sub-title modal-name-stage mb-2">
                                        {this.state.nameStageValue}
                                    </div>
                                    <div className="modal-sub-title">
                                        Stage with this name already exist, are you sure you want to override?
                                    </div>
                                    <Container className="w-75 mt-5">
                                        <Row>
                                            <Col lg={6} md={6}>
                                                <Button variant="danger" className="modal-button-save w-100" onClick={this.handleSendStage}>
                                                    YES, SAVE ANYWAY
                                                </Button>
                                            </Col>
                                            <Col lg={6} md={6}>
                                                <Button variant="danger" className="modal-button-discard w-100" onClick={this.props.handleCloseDuplicateStage}>
                                                    NO, I CHANGED MY MIND
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </>
                            }
                            <Container className="w-100">
                                <SnackBarMessage
                                    message={this.props.messageSnackBarStageSave}
                                    show={this.props.showSnackBarStageSave}
                                    success={this.props.successSnackBarStageSave}
                                    handleSetCloseSnackBar={this.props.handleSetCloseSnackBarStageSave}
                                />
                            </Container>
                            {this.props.showDuplicateStage === false && 
                                <div className="modal-body">
                                    <div className="modal-sub-title">
                                        Recent stages
                                    </div>
                                    <div className="container pl-5 pr-5 w-100">
                                        <ListStage
                                            recentlyStage={true}
                                            reloadStage={this.props.reloadStage}
                                            handleLoadStageFromJson={this.props.handleLoadStageFromJson}
                                            handleReloadListStage={this.props.handleReloadListStage}
                                            handleSetCloseModalLoadStage={this.props.handleSetCloseModalSaveStage}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
            </Modal>
        </>
        )
    }
}
export default ModalSaveStage;