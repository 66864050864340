import React from 'react';
import UserGuide from 'react-user-guide';

class UserSceneGuide extends React.Component{
    constructor(props){
        super(props);
        
        this.handleCheckExistElement = this.handleCheckExistElement.bind(this);
        this.handleParsedGuides = this.handleParsedGuides.bind(this);
        
        this.state = {
          buttonConfig : {
            yesText: 'Yes',
            noText: 'No',
            nextText: 'Next',
            skipText: 'Skip',
            finishText: 'Finish'
          },
          guides : [
            {
              querySelector: '.login',
              position: 'south',
              title: 'LOG IN',
              message: "Login to get a better experience. We’d recommend starting here. This is not required, but it gives you the option to save and finish the stage later."
            },
            {
              querySelector: '.tools-bar',
              position: 'east',
              title: 'TOOL BAR',
              message: 'These are the essential tools for moving and building the stage. Hover over any icon and a tooltip with a description will appear.'
            },
            {
              querySelector: '.col-params-ready-shape',
              position: 'west',
              title: 'PROPS SIDEBAR',
              message: 'You can use real elements from this sidebar. You can drag and drop props in the stage.'
            },
            {
              querySelector: '.add-image-button',
              position: 'south',
              title: 'UPLOAD NEW PROPS',
              message: 'You can upload your own props. Click this button and choose an image from your drive.'
            },
            {
              querySelector: '.description-params',
              position: 'south',
              title: 'ADD DESCRIPTION',
              message: 'When you’re finished placing your targets and props fill in the Stage Procedure form.'
            },
            {
              querySelector: '.print-scene',
              position: 'south',
              title: 'PRINT YOUR STAGE',
              message: 'You can print the stage on paper with your printer or print the stage to PDF using the virtual printer software, such as the free <a href="https://www.cutepdf.com/" target="_blank">CutePDFWriter</a>.'
            }
            
          ]
        }
    }
    handleCheckExistElement(currentIndex){
      if(document.querySelectorAll(this.state.guides[currentIndex].querySelector).length > 0){
        return true;
      }else{
        return false
      }
    }
    handleParsedGuides(){
      let guidesKeyFilter = Object.keys(this.state.guides).filter(((i) => this.handleCheckExistElement(i) === true));
      let counter = 0;
      const guidesKeyParsed = [];
      guidesKeyFilter.map(i => {
        counter++;
        return guidesKeyParsed[counter-1] = {
          querySelector : this.state.guides[i].querySelector,
          position : this.state.guides[i].position,
          title : this.state.guides[i].title + ' ' + counter + '/' + guidesKeyFilter.length,
          message : this.state.guides[i].message
        }
      });
      return guidesKeyParsed;
    }
    render(){
      let parsedGuides = this.handleParsedGuides();
       return(
         <> 
            <UserGuide 
              buttonConfig={this.state.buttonConfig} 
              guides={parsedGuides}
              guidesLength={parsedGuides.length}
              reloadGuide={this.props.reloadGuide}
              >
            </UserGuide>
        </>
       )
    }
}
export default UserSceneGuide;