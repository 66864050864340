import React from 'react';
import ListStage from './ListStage';
import Modal from '@material-ui/core/Modal';
import {ReactComponent as ListStageDarkIcon} from './icons/ListStageDarkIcon.svg';

class ModalMyStage extends React.Component{
    render(){
        return(
            <Modal className="modal-params-backdrop"
                open={this.props.showModalMyStage}
                onClose={this.props.handleSetCloseModalMyStage}
            >
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-params w-100">
                        <div className="modal-content modal-content-scroll">
                            <div className="params-header modal-header">
                                <div className="modal-title h4">
                                    <ListStageDarkIcon />
                                    <br />
                                    MY STAGES
                                </div>
                                <button type="button" className="close" onClick={this.props.handleSetCloseModalMyStage}>
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="modal-sub-title">
                                Here is a list of all recently saved stages.
                            </div>
                            <div className="modal-body">
                                <div className="container pl-5 pr-5 w-100">
                                    <ListStage
                                        recentlyStage={false}
                                        handleLoadStageFromJson={this.props.handleLoadStageFromJson}
                                        handleSetCloseModalLoadStage={this.props.handleSetCloseModalMyStage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>      
            </Modal>
        );
    }
}
export default ModalMyStage;