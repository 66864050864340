import React from 'react';
import ReactDOM from 'react-dom';
import StageBuilder from './StageBuilder';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

ReactDOM.render(
  <React.Fragment>
    <StageBuilder/>
  </React.Fragment>,
  document.getElementById('erw-root')
);