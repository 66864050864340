import React from 'react';
import {Col, Container, OverlayTrigger, Tooltip, Row} from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import ModalFormSceneBuilderParams from './ModalFormSceneBuilderParams';
import ModalMyStage from './ModalMyStage';
import ModalSaveStage from './ModalSaveStage';
import ModalLogIn from './ModalLogIn';
import ModalLogOut from './ModalLogOut';
import { ReactComponent as AddDescriptionLightIcon } from './icons/AddDescriptionLightIcon.svg';
import { ReactComponent as InstructionsIcon } from './icons/InstructionsIcon.svg';
import { ReactComponent as ListStageIcon } from './icons/ListStageIcon.svg';
import { ReactComponent as LoginLightIcon } from './icons/LoginLightIcon.svg';
import { ReactComponent as PrintIcon } from './icons/PrintIcon.svg';
import { ReactComponent as RemoveStageIcon } from './icons/RemoveStageIcon.svg';
import { ReactComponent as SaveStageLightIcon } from './icons/SaveStageLightIcon.svg';
import { ReactComponent as LogOutLightIcon } from './icons/LogOutLightIcon.svg';
import ReadyShapes from './ReadyShapes';
import UserSceneGuide from './UserSceneGuide';
import 'rsuite/dist/styles/rsuite-default.css';

class ToolsBar extends React.Component{
    constructor(props) {
        super(props);
        
        this.handlePrintWithPdf = this.handlePrintWithPdf.bind(this);
        this.handleSetLogIn = this.handleSetLogIn.bind(this);
        this.handleSetShowFormSceneBuilderParams = this.handleSetShowFormSceneBuilderParams.bind(this);
        this.handleScrollToParams = this.handleScrollToParams.bind(this);
        this.handleSubmitFormSceneBuilder = this.handleSubmitFormSceneBuilder.bind(this);
        this.handleSetShowUserSceneGuide = this.handleSetShowUserSceneGuide.bind(this);
        this.handleSetShowModalMyStage = this.handleSetShowModalMyStage.bind(this);
        this.handleSetShowModalLogOut = this.handleSetShowModalLogOut.bind(this);
        this.handleSetCloseFormSceneBuilderParams = this.handleSetCloseFormSceneBuilderParams.bind(this);
        this.handleSetCloseModalMyStage = this.handleSetCloseModalMyStage.bind(this);
        this.handleSetCloseModalLogOut = this.handleSetCloseModalLogOut.bind(this);
        this.windowCheckerMenu = this.windowCheckerMenu.bind(this);
        
        this.state = {
            reloadGuide : false,
            showModalForm: false,
            showModalMyStage: false,
            showModalLogOut : false
        }
    }
    componentDidMount(){
        window.addEventListener('windowCheckerMenu', this.windowCheckerMenu());
    }
    componentWillUnmount(){
        window.addEventListener('windowCheckerMenu', this.windowCheckerMenu());
    }
    windowCheckerMenu(){
        if(window.innerWidth >= 1100){
           return this.setState({show : true});
        }
    }
    handlePrintWithPdf(){
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const colScene = document.querySelector('.col-scenebuilder-16');
        html2canvas(colScene, {
            onclone: function (documentClone){
                if(documentClone.querySelector('.button-params-remove')){
                    documentClone.querySelector('.button-params-remove').style.display = 'none';
                }
                documentClone.querySelector('.scenebuilder-logo').style.position = 'sticky';
                documentClone.querySelector('.card-scenebuilder').style.boxShadow = "none";
            },
        })
        .then((canvas) => {
            const dataImage = canvas.toDataURL('image/png');
            console.log(dataImage);
            const documentPDF = new jsPDF("p", "mm", "a4");
            const propsImage = documentPDF.getImageProperties(dataImage);
            const widthPDF = documentPDF.internal.pageSize.getWidth();
            const heightPDF = (propsImage.height * widthPDF) / propsImage.width;
            documentPDF.addImage(dataImage, 'JPEG', 0, 0, widthPDF, heightPDF);
            documentPDF.setProperties({
                title: "TargetBarn"
            });
            if(isSafari){
                documentPDF.autoPrint();
            }
            return window.open(documentPDF.output('bloburl'),'_blank');
        })
    }
    handleScrollToParams(){
        return window.scrollTo({
            top: this.props.refsScrollElement.current.offsetTop + (this.props.refsScrollElement.current.scrollHeight),
            behavior: 'smooth'
        });
    }
    handleSetShowFormSceneBuilderParams(){
        return this.setState({
            showModalForm: true
        })
    }
    handleSetShowModalMyStage(){
        if(this.props.isLogged === true){
            return this.setState({
                showModalMyStage : true
            });
        }
        return this.props.handleSetShowModalLogIn();
    }
    handleSetShowModalLogOut(){
        return this.setState({
            showModalLogOut : true
        });
    }
    handleSubmitFormSceneBuilder(){
        if(this.props.handleSubmitFormSceneBuilder() === true){
            this.handleSetCloseFormSceneBuilderParams();
            return this.handleScrollToParams();
        }
    }
    async handleSetLogIn(boolValue){
        return this.props.handleSetLogIn(boolValue);
    }
    async handleSetShowUserSceneGuide(){
        localStorage.removeItem('ug_userGuide-guideKey');
        return await this.setState({
            reloadGuide : true
        });
    }
    async handleSetCloseFormSceneBuilderParams(){
        return this.setState({
            showModalForm:false
        });
    }
    async handleSetCloseModalMyStage(){
        return this.setState({
            showModalMyStage : false
        });
    }
    async handleSetCloseModalLogOut(){
        return this.setState({
            showModalLogOut : false
        });
    }
    render(){
        return (
            <>
                <Row className="row-scenebuilder-tools">
                    <Col>
                        <Container className="container-scenebuilder-tools">
                           <Row className="row-scenebuilder-button">
                                <Col sm={2} xs={12} md={1} className="col-max-mobile-scenebuilder-button">
                                    {!this.props.isLogged ?
                                        <OverlayTrigger
                                            trigger={this.props.toolTip.trigger}
                                            placement={this.props.toolTip.placement}
                                            delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                            overlay={<Tooltip>Log In</Tooltip>}
                                        >
                                                <IconButton className="icon-scenebuiler-button login" onClick={this.props.handleSetShowModalLogIn}>
                                                    <LoginLightIcon/>
                                                </IconButton>
                                        </OverlayTrigger>
                                    :
                                        <OverlayTrigger
                                            trigger={this.props.toolTip.trigger}
                                            placement={this.props.toolTip.placement}
                                            delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                            overlay={<Tooltip>Log out</Tooltip>}
                                    >
                                                <IconButton className="icon-scenebuiler-button logout" onClick={this.handleSetShowModalLogOut}>
                                                    <LogOutLightIcon/>
                                                </IconButton>
                                        </OverlayTrigger>
                                    }
                                </Col>
                               <Col sm={1} className="col-scenebuilder-button">
                                    <OverlayTrigger
                                        trigger={this.props.toolTip.trigger}
                                        placement={this.props.toolTip.placement}
                                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                        overlay={<Tooltip>Add a description to the stage</Tooltip>}
                                    >
                                            <IconButton className="icon-scenebuiler-button description-params" onClick={this.handleSetShowFormSceneBuilderParams}>
                                                <AddDescriptionLightIcon/>
                                            </IconButton>
                                    </OverlayTrigger>
                                </Col>
                                <Col sm={1} className="col-scenebuilder-button">
                                    <OverlayTrigger
                                        trigger={this.props.toolTip.trigger}
                                        placement={this.props.toolTip.placement}
                                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                        overlay={<Tooltip>Display the list of stages</Tooltip>}
                                    >
                                            <IconButton className="icon-scenebuiler-button" onClick={this.handleSetShowModalMyStage}>
                                                <ListStageIcon className="icon-img-button"/>
                                            </IconButton>
                                    </OverlayTrigger>
                                </Col>
                                <Col sm={1} className="col-scenebuilder-button">
                                    <OverlayTrigger
                                        trigger={this.props.toolTip.trigger}
                                        placement={this.props.toolTip.placement}
                                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                        overlay={<Tooltip>Print the stage with params</Tooltip>}
                                    >
                                            <IconButton className="icon-scenebuiler-button print-scene" onClick={this.handlePrintWithPdf}>
                                                <PrintIcon className="icon-img-button"/>
                                            </IconButton>
                                    </OverlayTrigger>
                                </Col>
                                <Col sm={1} className="col-scenebuilder-button">
                                    <OverlayTrigger
                                        trigger={this.props.toolTip.trigger}
                                        placement={this.props.toolTip.placement}
                                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                        overlay={<Tooltip>Save current stage</Tooltip>}
                                    >
                                        <IconButton className="icon-scenebuiler-button" onClick={this.props.handleSetShowModalSaveStage}>
                                            <SaveStageLightIcon className="icon-img-button"/>
                                        </IconButton>
                                    </OverlayTrigger>
                                </Col>
                                <Col sm={1} className="col-scenebuilder-button">
                                    <OverlayTrigger
                                        trigger={this.props.toolTip.trigger}
                                        placement={this.props.toolTip.placement}
                                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                        overlay={<Tooltip>Remove stage</Tooltip>}
                                    >
                                        <IconButton className="icon-scenebuiler-button" onClick={this.props.handleClear}>
                                            <RemoveStageIcon className="icon-img-button" />
                                        </IconButton>
                                    </OverlayTrigger>
                                </Col>
                                <Col sm={1} className="col-scenebuilder-button">
                                    <OverlayTrigger
                                        trigger={this.props.toolTip.trigger}
                                        placement={this.props.toolTip.placement}
                                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                        overlay={<Tooltip>Show instructions</Tooltip>}
                                    >
                                        <IconButton className="icon-scenebuiler-button" onClick={this.handleSetShowUserSceneGuide}>
                                            <InstructionsIcon className="icon-img-button"/>
                                        </IconButton>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Container style={{height:this.props.height, overflowY:'scroll', overflowX:'hidden'}} className="container-scenebuilder-tools mt-1">
                            <ReadyShapes
                                toolTip={this.props.toolTip}
                                handleAddImage={this.props.handleAddImage}
                                handleAddShape={this.props.handleAddShape}
                            />
                        </Container>
                    </Col>
                </Row>
                <ModalFormSceneBuilderParams
                    paramsFormStageSaved={this.props.paramsFormStageSaved}
                    showModalForm={this.state.showModalForm}
                    handleChangeParamsItem={this.props.handleChangeParamsItem}
                    handleSubmitFormSceneBuilder={this.handleSubmitFormSceneBuilder}
                    handleSetCloseFormSceneBuilderParams={this.handleSetCloseFormSceneBuilderParams}
                />
                <UserSceneGuide 
                    reloadGuide={this.state.reloadGuide}
                />
                <ModalLogIn 
                    showModalLogIn={this.props.showModalLogIn}
                    handleSetCloseModalLogIn={this.props.handleSetCloseModalLogIn}
                    handleSetLogIn={this.handleSetLogIn}
                />         
                <ModalMyStage
                    showModalMyStage={this.state.showModalMyStage}
                    handleSetCloseModalMyStage={this.handleSetCloseModalMyStage}
                    handleLoadStageFromJson={this.props.handleLoadStageFromJson}
                />
                <ModalSaveStage
                    showDuplicateStage={this.props.showDuplicateStage}
                    reloadStage={this.props.reloadStage}
                    showModalSaveStage={this.props.showModalSaveStage}
                    showSnackBarStageSave={this.props.showSnackBarStageSave}
                    successSnackBarStageSave={this.props.successSnackBarStageSave}
                    messageSnackBarStageSave={this.props.messageSnackBarStageSave}   
                    handleCloseDuplicateStage={this.props.handleCloseDuplicateStage}             
                    handleSetCloseModalSaveStage={this.props.handleSetCloseModalSaveStage}
                    handleLoadStageFromJson={this.props.handleLoadStageFromJson}
                    handleReloadListStage={this.props.handleReloadListStage}
                    handleSaveStage={this.props.handleSaveStage}
                    handleSendStage={this.props.handleSendStage}
                    handleSetCloseSnackBarStageSave={this.props.handleSetCloseSnackBarStageSave}
                        />
                <ModalLogOut
                    showModalLogOut={this.state.showModalLogOut}
                    handleSetCloseModalLogOut={this.handleSetCloseModalLogOut}
                    handleLoadStageFromJson={this.props.handleLoadStageFromJson}
                    handleSetLogIn={this.props.handleSetLogIn}
                    handleSetShowLastModal={this.handleSetShowModalLogOut}
                    handleSetCloseLastModal={this.handleSetCloseModalLogOut}
                />
            </>
        )
    }
}
export default ToolsBar;