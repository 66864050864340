import {ApiStageBuilder} from './ApiStageBuilder';

/*
    User action
*/

export const PasswordCodeRecovery = async (email) => {
    let passwordRecoveredCodeParams = {};
    const options = {
        method : 'stages/recoveryCodePassword',
        body : JSON.stringify({
            email : email
        })
    }
    await ApiStageBuilder(options).then(result => {
        passwordRecoveredCodeParams['success'] = result.success;
        passwordRecoveredCodeParams['message'] = result.message;
    })
    return passwordRecoveredCodeParams;
}

export const PasswordRecovery = async (code, email, password) => {
    let passwordRecoveryParams = {};
    const options = {
        method : 'stages/recoveryPassword',
        body : JSON.stringify({
            email : email,
            code : code,
            password : password
        })
    }
    await ApiStageBuilder(options).then(result => {
        passwordRecoveryParams['success'] = result.success;
        passwordRecoveryParams['message'] = result.message;
    })
    return passwordRecoveryParams;
}

export const RegisterUser = async (userParams) => {
    let registerParams = {};
    let isRegistered = false;
    const options = {
        method : 'stages/registerUser',
        body : JSON.stringify({
            userParams : userParams
        })
    }
    await ApiStageBuilder(options).then(result => {
        if(result.success === true){
            if(result.params.logged === true){
                localStorage.setItem('sb_login', userParams.login);
                localStorage.setItem('sb_hashLogin', result.params.storageLogin);
                isRegistered = true;
            }
        }
        registerParams['is_registered'] = isRegistered;
        registerParams['success'] = result.success
        registerParams['message'] = result.message;
    })
    return registerParams;
}

export const LogIn = async (loginOrEmail, password, hashLogin) => {
    let isLogged = false;
    const options = {
        method : 'stages/logIn',
        body : JSON.stringify({
            loginOrEmail : loginOrEmail,
            password : password,
            hashLogin : hashLogin
        })
    }
    await ApiStageBuilder(options).then(result => {
        if(result.params.logged === true){
            localStorage.setItem('sb_login', result.params.login);
            localStorage.setItem('sb_hashLogin', result.params.storageLogin);
            isLogged = true;
        }
    })
    return isLogged;
}

export const CheckLogIn = async (login, hashLogin) => {
    let userResponse = {};
    const options = {
        method : 'stages/logIn',
        body : JSON.stringify({
            login : login,
            hashLogin : hashLogin
        })
    }
    await ApiStageBuilder(options).then(result => {
        userResponse['success'] = result.success;
        userResponse['is_logged'] = result.params.logged
    });
    return userResponse;
}

export const LogOut = async () => {
    localStorage.removeItem('sb_login');
    localStorage.removeItem('sb_hashLogin');
    return true;
}

/*
    Stage action
*/

export const GetListStage = async (limit, offset) => {
    let stageResponse = {};
    const options = {
        method : 'stages/getAllStages',
        body : JSON.stringify({
            limit : limit,
            offset : offset,
            login : localStorage.getItem('sb_login'),
            hashLogin : localStorage.getItem('sb_hashLogin')
        })
    };
    await ApiStageBuilder(options).then(result => {
        if(result.success === true){
            stageResponse['stage_list'] = result.params.paramsStage.records;
            stageResponse['stage_count'] = result.params.paramsStage.countRecords;
        }
    });
    return stageResponse;
}

export const GetStage = async (idStage) => {
    let stageResponse = {};
    const options = {
        method : 'stages/getStage',
        body : JSON.stringify({
            idStage : idStage,
            login : localStorage.getItem('sb_login'),
            hashLogin : localStorage.getItem('sb_hashLogin')
        })   
    }
    await ApiStageBuilder(options).then(result => {
        stageResponse['success'] = result.success;
        stageResponse['body_stage'] = result.params.body_stage;
    })
    return stageResponse;
}

export const RemoveStage = async (idStage) => {
    let isStageRemoved = false;
    const options = {
        method : 'stages/removeStage',
        body : JSON.stringify({
            idStage : idStage,
            login : localStorage.getItem('sb_login'),
            hashLogin : localStorage.getItem('sb_hashLogin')
        })
    }
    await ApiStageBuilder(options).then(result => {
        isStageRemoved = result.success;
    })
    return isStageRemoved;
}

export const CheckStageExist = async (nameStage) => {
    let stageResponse = {};
    const options = {
        method : 'stages/checkStageExist',
        body : JSON.stringify({
            nameStage : nameStage,
            login : localStorage.getItem('sb_login'),
            hashLogin : localStorage.getItem('sb_hashLogin')
        })
    }
    await ApiStageBuilder(options).then(result => {
        stageResponse['success'] = result.success;
        stageResponse['is_stage_exist'] = result.params.boolStageExist;
    })
    return stageResponse;
}

export const SendStage = async (nameStage, baseImage, jsonStage) => {
    let isSended = false;
    const options = {
        method : 'stages/addStage',
        body : JSON.stringify({
            nameStage : nameStage,
            baseImage : baseImage,
            jsonStage : jsonStage,
            login : localStorage.getItem('sb_login'),
            hashLogin : localStorage.getItem('sb_hashLogin')
        })
    }
    await ApiStageBuilder(options).then(result => {
        isSended = result.success;
    })
    return isSended;
}

/*
    Shape action
*/

export const LoadShapes = async() => {
    let icons = {};
    const options = {
        method : 'props/getAllShapes'
    };
    await ApiStageBuilder(options).then(result => {
        icons = result.iconArray
    });
    return icons;
}