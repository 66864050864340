import React from 'react';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as BringFrontIcon } from './icons/BringFrontIcon.svg';
import { ReactComponent as BringForwardIcon } from './icons/BringForwardIcon.svg';
import { ReactComponent as CloneIcon } from './icons/CloneIcon.svg';
import { ReactComponent as DeleteIcon } from './icons/DeleteIcon.svg';
import { ReactComponent as SendBackwardIcon } from './icons/SendBackwardIcon.svg';
import { ReactComponent as SendBackIcon } from './icons/SendBackIcon.svg';

class SelectedItemActionsBar extends React.Component{
    handleBringFrontStage(){
        return this.props.handleBringFrontStage();
    }
    handleBringForwardStage(){
        return this.props.handleBringForwardStage()
    }
    handleCloneStage(){
        return this.props.handleClone();
    }
    handleDeleteStage(){
        return this.props.handleRemoveSelected();
    }
    handleSendBackwardStage(){
        return this.props.handleSendBackwardStage();
    }
    handleSendBackStage(){
        return this.props.handleSendBackStage();
    }
    render(){
        const classCustomIcon = 'custom-icon-scenebuilder icon-disabled-'+this.props.blockedSelected;
        return (
            <Row className="shape-container">
                <Col xs={2} sm={2}>
                    <OverlayTrigger
                        trigger={this.props.toolTip.trigger}
                        placement={this.props.toolTip.placement}
                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                        overlay={<Tooltip>Bring to Front</Tooltip>}
                    >
                            <IconButton color="secondary" disabled={!this.props.blockedSelected} onClick={()=>this.handleBringFrontStage()}>
                                <BringFrontIcon className={classCustomIcon}/>
                            </IconButton>
                    </OverlayTrigger>
                </Col>
                <Col xs={2} sm={2}>
                    <OverlayTrigger
                        trigger={this.props.toolTip.trigger}
                        placement={this.props.toolTip.placement}
                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                        overlay={<Tooltip>Bring Forward</Tooltip>}
                    >
                            <IconButton color="secondary" disabled={!this.props.blockedSelected} onClick={()=>this.handleBringForwardStage()}>
                                <BringForwardIcon className={classCustomIcon}/>
                            </IconButton>
                    </OverlayTrigger>
                </Col>
                <Col xs={2} sm={2}>
                    <OverlayTrigger
                        trigger={this.props.toolTip.trigger}
                        placement={this.props.toolTip.placement}
                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                        overlay={<Tooltip>Send Backward</Tooltip>}
                    >
                            <IconButton color="secondary" disabled={!this.props.blockedSelected} onClick={()=>this.handleSendBackwardStage()}>
                                <SendBackwardIcon className={classCustomIcon}/>
                            </IconButton>
                    </OverlayTrigger>
                </Col>
                <Col xs={2} sm={2}>
                    <OverlayTrigger
                        trigger={this.props.toolTip.trigger}
                        placement={this.props.toolTip.placement}
                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                        overlay={<Tooltip>Send to Back</Tooltip>}
                    >
                            <IconButton color="secondary" disabled={!this.props.blockedSelected} onClick={()=>this.handleSendBackStage()}>
                                <SendBackIcon className={classCustomIcon}/>
                            </IconButton>
                    </OverlayTrigger>
                </Col>
                <Col xs={2} sm={2}>
                    <OverlayTrigger
                        trigger={this.props.toolTip.trigger}
                        placement={this.props.toolTip.placement}
                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                        overlay={<Tooltip>Delete element</Tooltip>}
                    >
                            <IconButton color="secondary" disabled={!this.props.blockedSelected} onClick={()=>this.handleDeleteStage()}>
                                <DeleteIcon/>
                            </IconButton>
                    </OverlayTrigger>
                </Col>
                <Col xs={2} sm={2}>
                    <OverlayTrigger
                        trigger={this.props.toolTip.trigger}
                        placement={this.props.toolTip.placement}
                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                        overlay={<Tooltip>Clone element</Tooltip>}
                    >
                            <IconButton color="secondary" disabled={!this.props.blockedSelected} onClick={()=>this.handleCloneStage()}>
                                <CloneIcon className={classCustomIcon}/>
                            </IconButton>
                    </OverlayTrigger>
                </Col>
            </Row>
        )
    }
}
export default SelectedItemActionsBar;