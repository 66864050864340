import React from 'react';
import {CheckStageExist, SendStage, CheckLogIn} from './ModelStage';
import {Card, Container, Col, Row} from 'react-bootstrap';
import ColorBar from './ColorBar';
import FormSceneBuilderDisplay from './FormSceneBuilderDisplay';
import imageToBase64 from 'image-to-base64/browser';
import { ReactComponent as StageBuilderIcon } from './icons/StageBuilderIcon.svg';
import SelectedItemActionsBar from './SelectedItemActionsBar';
import ToolsButtons from './ToolsButtons';
import ToolsBar from './ToolsBar';
import {SketchField, Tools} from 'react-sketch2';
import { fabric } from "fabric";

export class StageBuilder extends React.Component{
    canvas;
    constructor(props){
        super(props);
        this.handleAddText = this.handleAddText.bind(this);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleAddShape = this.handleAddShape.bind(this);
        this.handleAlertPrompt = this.handleAlertPrompt.bind(this);
        this.handleBringFrontStage = this.handleBringFrontStage.bind(this);
        this.handleBringForwardStage = this.handleBringForwardStage.bind(this);
        this.handleBlockedSelected = this.handleBlockedSelected.bind(this);
        this.handleChangeParamsItem = this.handleChangeParamsItem.bind(this);
        this.handleChangeTool = this.handleChangeTool.bind(this);
        this.handleCloseDuplicateStage = this.handleCloseDuplicateStage.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleClone = this.handleClone.bind(this);
        this.handleCheckLogIn = this.handleCheckLogIn.bind(this);
        this.handleDropShape = this.handleDropShape.bind(this);
        this.handleLoadStageFromJson = this.handleLoadStageFromJson.bind(this);
        this.handleSubmitFormSceneBuilder = this.handleSubmitFormSceneBuilder.bind(this);
        this.handleActionByKey = this.handleActionByKey.bind(this);
        this.handleParamsFormRemove = this.handleParamsFormRemove.bind(this);
        this.handleRemoveSelected = this.handleRemoveSelected.bind(this);
        this.handleRedo = this.handleRedo.bind(this);
        this.handleRightClickSelect = this.handleRightClickSelect.bind(this);
        this.handleReloadListStage = this.handleReloadListStage.bind(this);
        this.handleUndo = this.handleUndo.bind(this);
        this.handleSaveStage = this.handleSaveStage.bind(this);
        this.handleSendStage = this.handleSendStage.bind(this);
        this.handleSetLogIn = this.handleSetLogIn.bind(this);
        this.handleSetCloseSnackBarStageSave = this.handleSetCloseSnackBarStageSave.bind(this);
        this.handleSetShowModalSaveStage = this.handleSetShowModalSaveStage.bind(this);
        this.handleSetCloseModalSaveStage = this.handleSetCloseModalSaveStage.bind(this);
        this.handleSetShowModalLogIn = this.handleSetShowModalLogIn.bind(this);
        this.handleSetCloseModalLogIn = this.handleSetCloseModalLogIn.bind(this);
        this.handleSketchChange = this.handleSketchChange.bind(this);
        this.handleSelectedChangeColorElement = this.handleSelectedChangeColorElement.bind(this);
        this.handleSelectedElement = this.handleSelectedElement.bind(this);
        this.handleSelectedElementPosition = this.handleSelectedElementPosition.bind(this);
        this.handleSendBackwardStage = this.handleSendBackwardStage.bind(this);
        this.handleSendBackStage = this.handleSendBackStage.bind(this);
        this.windowCheckerSceneBuilder = this.windowCheckerSceneBuilder.bind(this);
        
        this.state = ({
            actualShape : Tools.Select,
            blockedSelected : false,
            canUndo : false,
            canRedo : false,
            lastShape : false,
            lineColor : 'black',
            height : '',
            isLogged : false,
            isMobile: false,
            messageSnackBarStageSave : '',
            reloadStage : false,
            stageLoaded : 0,
            selectedElement : false,
            selectedElementTop : 0,
            selectedElementLeft : 0,
            selectedImg: false,
            showDuplicateStage : false,
            showSnackBarStageSave : false,
            showModalLogIn : false,
            showModalSaveStage : false,
            successSnackBarStageSave : false,
            toolTip:{
                trigger : ['hover', 'hover'],
                placement : 'top',
                show : 850,
                hide : 100
            },
            paramsFormStageSaved : {},
            paramsFormStage : {},
        });
        
        this.refsScrollElement = React.createRef([]);
        this.mapKey = {};
        this.timeOutMoveKey = 0;
    }
    componentDidMount(){
        this.handleCheckLogIn();
        this.canvas = this._sketch._fc;
        window.addEventListener('keydown', this.handleActionByKey);
        window.addEventListener('keyup', this.handleActionByKey);
        window.addEventListener('contextmenu',this.handleRightClickSelect);
        window.addEventListener('beforeunload', this.handleAlertPrompt)
        window.addEventListener('windowCheckerSceneBuilder', this.windowCheckerSceneBuilder());
    }
    componentWillUnmount(){
        window.removeEventListener('keydown', this.handleActionByKey);
        window.removeEventListener('keyup', this.handleActionByKey);
        window.removeEventListener('contextmenu',this.handleRightClickSelect);
        window.removeEventListener('beforeunload', this.handleAlertPrompt)
        window.removeEventListener('windowCheckerSceneBuilder', this.windowCheckerSceneBuilder());
    }
    handleAddText(text){
        this.handleChangeTool(Tools.Pan);
        return this._sketch.addText(text);    
    }
    handleAddImage(base64Image){
        this._sketch.addImg(base64Image);
        return this.handleChangeTool(Tools.Select);
    }
    handleAddShape(dataUrl, paramsShape){
        this.parserParamsShape(paramsShape);
        this.handleChangeTool(Tools.Select);
        return this._sketch.addImg(dataUrl, this.parserParamsShape(paramsShape));
    }
    handleAlertPrompt(event){
        event.preventDefault();
        return event.returnValue = 'Are you sure to leave';
    }
    handleBelowSelected(event){
        const containerDimension = {
            left : 0,
            top : 0,
            width : 0,
            height : 0
        }
        const objects = this.canvas.getObjects();
        const activeObjects = this.canvas.getActiveObjects();
        const nonActiveObjectsInContainer = [];

        activeObjects.map(element => {
            if(element.left > containerDimension.left){
                containerDimension.left = element.left
            }
            if(element.top > containerDimension.top){
                containerDimension.top = element.top
            }
            if(element.width > containerDimension.width){
                containerDimension.width = element.width * element.scaleX;
            }
            if(element.height > containerDimension.height){
                containerDimension.height = element.height * element.scaleY;
            }
            return containerDimension;
        })

        objects.map(element => {
            let counter = 0;
            activeObjects.map(elementInside => {
                if(element.matrixCache?.key === elementInside.matrixCache?.key){
                    counter++;
                }
                return counter;
            })
            if(counter === 0 && element.left + (element.width * element.scaleX) >= containerDimension.left && element.left < containerDimension.left + containerDimension.width){
                if(element.top + (element.height * element.scaleY) >= containerDimension.top && element.top < containerDimension.top + containerDimension.height){
                    nonActiveObjectsInContainer.push(element);
                }
            }
            return nonActiveObjectsInContainer;
        })
        const elementToActive = nonActiveObjectsInContainer.filter(element => {
            if(element.type !== 'circle'){
                return event.layerX >= element.left && event.layerX < element.left + (element.width * element.scaleX) && event.layerY >= element.top && event.layerY <= element.top + (element.height * element.scaleY);
            }else{
                const marginMovment = 20;
                return event.layerX >= element.left - marginMovment && event.layerX < element.left + marginMovment + (element.width * element.scaleX) && event.layerY >= element.top - marginMovment && event.layerY <= element.top + marginMovment + (element.height * element.scaleY);
            }
        });
        if(typeof elementToActive[0] !== 'undefined'){
            this.canvas.discardActiveObject().setActiveObject(elementToActive[0]).renderAll();
        }
    }
    handleBringFrontStage(){
        this.canvas.getActiveObjects().forEach(activeObject => {
            this.canvas.bringToFront(activeObject);
        });
        this.canvas.discardActiveObject().renderAll();
        return this.handleBlockedSelected(false);
    }
    handleBringForwardStage(){
        this.canvas.getActiveObjects().forEach(activeObject => {
            this.canvas.sendToBack(activeObject);
        });
        this.canvas.discardActiveObject().renderAll();
        return this.handleBlockedSelected(false);
    }
    handleBlockedSelected(boolValue){
        return this.setState({
            blockedSelected: boolValue
        });
    }
    handleChangeParamsItem(paramKey, paramValue){
        return this.setState(Object.assign(this.state.paramsFormStageSaved, {[paramKey] : paramValue}));
    }
    handleChangeTool(tool){
        let selectedElementValue = Boolean(false);
        if(tool === 'select' && this.state.selectedElement){
            this.handleBlockedSelected(true);
            selectedElementValue = true;
        }else{
            this.handleBlockedSelected(false);
            this.canvas.discardActiveObject().renderAll();
        }
        return this.setState({
            actualShape:tool,
            selectedElement:selectedElementValue
        });
    }
    handleCloseDuplicateStage(){
        this.setState({
            showDuplicateStage : false
        });
    }
    handleClear(){
        this._sketch.clear();
        return this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo()
        });
    }
    handleClone(){
        const activeObjectsCurrent = this.canvas.getActiveObjects();
        let leftSpace = 0
        let topSpace = 0;
        if(activeObjectsCurrent.length <= 1){
            leftSpace = 10;
            topSpace = 10;
        }else{
            leftSpace = 250;
            topSpace = 250; 
        }
        return activeObjectsCurrent.forEach(activeObject => {
            activeObject.clone((cloned) => (
                this.canvas.add(
                    cloned.set({
                        left: cloned.left + leftSpace,
                        top: cloned.top + topSpace
                    })
                ))
            );
        });
    }
    handleCheckLogIn(){
        const login = localStorage.getItem('sb_login');
        const hashLogin = localStorage.getItem('sb_hashLogin');
        CheckLogIn(login, hashLogin).then(result => {
           if(result.success === true){
                this.setState({
                    isLogged : Boolean(result.is_logged)
               })
            }
        })
    }
    handleDropShape(event){
        event.preventDefault();
        const shapeUrl = window.localStorage.getItem('dragShape');
        const scale = Number(localStorage.getItem('scale'));
        const canvas = this.canvas;
        imageToBase64(shapeUrl)
        .then(
            (imageConvert) => {
                if(shapeUrl){
                    if(this.handleGetUrlExtension(shapeUrl) !== 'svg'){
                        fabric.Image.fromURL('data:image/jpeg;base64,'+imageConvert, (oImg) => {
                            let imgHeight , imgWidth;
                            if(scale){
                                oImg.scale(scale);
                                imgHeight = oImg.getScaledHeight();
                                imgWidth = oImg.getScaledWidth();
                            }
                            else{
                                imgHeight = oImg.height;
                                imgWidth = oImg.width;
                            }
                            const viewportTransform = this.canvas.viewportTransform;
                            const paramsShape = {
                                top: event.pageY - (this.canvas._offset.top + viewportTransform[5]) - (imgHeight * 0.5),
                                left: event.pageX - (this.canvas._offset.left + viewportTransform[4]) - (imgWidth * 0.5)
                            }
                            oImg.set({
                                left: paramsShape.left,
                                top: paramsShape.top
                            });
                            canvas.add(oImg);
                        });
                    }
                    else{
                        fabric.loadSVGFromURL(shapeUrl, function(objects, options) {
                        let svgHeight, svgWidth;    
                        const svg = fabric.util.groupSVGElements(objects, options);
                            if(scale){
                                svg.scale(scale);
                                svgHeight = svg.getScaledHeight();
                                svgWidth = svg.getScaledWidth();
                            }else{
                                svgHeight = svg.height;
                                svgWidth = svg.width;
                            }
                            const viewportTransform = canvas.viewportTransform;
                            const paramsShape = {
                                top: event.pageY - (canvas._offset.top + viewportTransform[5]) - (svgHeight * 0.5),
                                left: event.pageX - (canvas._offset.left + viewportTransform[4]) - (svgWidth * 0.5)
                            }
                            svg.set({
                                left: paramsShape.left,
                                top : paramsShape.top,
                            })
                            canvas.add(svg);
                        })
                    }
                    canvas.renderAll();
                    return this.handleChangeTool(Tools.Select);
                }
            }
        )
    }
    handleGetUrlExtension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }
    handleLoadStageFromJson(json){
        this._sketch.fromJSON(json)
        return true;
    }
    handleSaveStage(nameStage){
        CheckStageExist(nameStage).then(result => {
            if(result.success === true && result.is_stage_exist === false){
                return this.handleSendStage(nameStage);
            }else{
                this.setState({
                    showDuplicateStage : true  
                })
            }
        })
    }
    handleSendStage(nameStage){
        const baseImage = this.canvas.toDataURL("image/png", 1.0);
        const jsonStage = this._sketch.toJSON();
        SendStage(nameStage, baseImage, jsonStage).then(result => {
            if(result === true){
                if(this.state.showDuplicateStage === true){
                    this.handleCloseDuplicateStage();
                }
                return this.setState({
                    reloadStage : true,
                    showSnackBarStageSave : true,
                    successSnackBarStageSave : true,
                    messageSnackBarStageSave : 'File Succesfuly saved',
                });
            }
        })
    }
    handleSetLogIn(boolValue){
        return this.setState({
            isLogged : Boolean(boolValue)
        });
    }
    handleSetCloseSnackBarStageSave(){
        return this.setState({
            showSnackBarStageSave : false
        });
    }
    handleSetShowModalSaveStage(){
        if(this.state.isLogged === true){
            return this.setState({
                showModalSaveStage : true
            });
        }
        return this.handleSetShowModalLogIn();
    }
    async handleSetCloseModalSaveStage(){
        return this.setState({
            showModalSaveStage : false
        });
    }
    handleSetShowModalLogIn(){
        return this.setState({
            showModalLogIn : true
        });
    }
    async handleSetCloseModalLogIn(){
        return this.setState({
            showModalLogIn : false
        });
    }
    handleSubmitFormSceneBuilder(){
        const paramsStageParsed = {};
        let counterNewParams = 0;
        Object.keys(this.state.paramsFormStageSaved).map(key => {
            if(this.state.paramsFormStageSaved[key] !== '' && this.state.paramsFormStageSaved[key] !== 0 && this.state.paramsFormStageSaved[key] !== '0'){
                paramsStageParsed[key] = this.state.paramsFormStageSaved[key]; 
                counterNewParams++;
            }
            return paramsStageParsed;
        })
        this.setState({paramsFormStage : paramsStageParsed})
        if(Object.keys(paramsStageParsed).length > 0 || counterNewParams > 0){
            return true;
        }else{
            return false;
        }
    }

    /*
        Key action
    */

    handleActionByKey(event){
        
        event = event || event; // to deal with IE

        this.mapKey[event.code] = event.type === 'keydown';
        
        /*
            Handle tool select
        */

        if(this.mapKey['ControlLeft'] === true && this.mapKey['Space'] === true){
            this.handlePreventAction(event);
            return this.handleChangeTool(Tools.Select);
        }

        /*
            Move element top 10/1 px
        */
        
        if(this.mapKey['ArrowUp'] === true){
            this.handlePreventAction(event);
            this.handleMoveByKey('top', (this.mapKey['ShiftLeft'] === true)? -1 : -10);
        }

        /*
            Move element bottom 10/1 px
        */

        if(this.mapKey['ArrowDown'] === true){
            this.handlePreventAction(event);
            return this.handleMoveByKey('top', (this.mapKey['ShiftLeft'] === true)? 1 : 10);
        }

        /*
            Move element left 10/1 px
        */

        if(this.mapKey['ArrowLeft'] === true){
            this.handlePreventAction(event);
            return this.handleMoveByKey('left', (this.mapKey['ShiftLeft'] === true)? -1 : -10);
        }

        /*
            Move element right 10/1 px
        */

        if(this.mapKey['ArrowRight'] === true){
            this.handlePreventAction(event);
            return this.handleMoveByKey('left', (this.mapKey['ShiftLeft'] === true)? 1 : 10);
        }
        
        /*
            Redo Action
        */
        
        if(this.mapKey['ControlLeft'] === true && this.mapKey['ShiftLeft'] === true && this.mapKey['KeyZ'] === true){
            return this.handleRedo();
        }
    
        /*
            Undo action
        */

        if(this.mapKey['ControlLeft'] === true && this.mapKey['KeyZ']){
            return this.handleUndo();
        }

        /*
            Delete Action
        */
        
        if(this.mapKey['Delete'] === true){
            return this.handleDeleteByKey();
        }

        /*
            Save action
        */

        if(this.mapKey['ControlLeft'] === true && this.mapKey['KeyS'] === true){
            this.handlePreventAction(event);
            if(document.getElementsByClassName('userGuide--modal').length === 0 && document.getElementsByClassName('modal-dialog').length === 0){
                return this.handleSetShowModalSaveStage();
            }
        }
    }
    handlePreventAction(event){
        event.preventDefault();
        event.stopPropagation();
    }
    handleDeleteByKey(){
        this.setState({
            selectedElement : false
        });
        this._sketch.removeSelected();
        return this.handleChangeTool(this.state.actualShape);
    }
    handleMoveByKey(direction, valuePixel){
        clearTimeout(this.timeOutMoveKey);
        if(this.state.selectedElement === true){
            this.setState({
                selectedElement : false
            });
        }
        const activeObjects = this.canvas.getActiveObjects();
        Object.values(activeObjects).map(element => {
            if(typeof element.group !== 'undefined'){
                element.group[direction] = element.group[direction] + valuePixel
                element.group.setCoords();
            }else{
                element[direction] = element[direction] + valuePixel
                element.setCoords();
            }
            return element;
        })
        this.canvas.renderAll();
        this.timeOutMoveKey = setTimeout(() => {
            this.handleSelectedElement();
        }, 300);
    }
    handleParamsFormRemove(){
        return this.setState({
            paramsFormStage : {},
            paramsFormStageSaved: {}
        });
    }
    async handleRemoveSelected(){
        this._sketch.removeSelected();
        await this.setState({
            selectedElement : false
        });
        return this.handleChangeTool(this.state.actualShape);
    }
    handleRedo(){
        this.canvas.discardActiveObject();
        this._sketch.redo();
        this.setState({
            selectedElement : false,
            canUndo : this._sketch.canUndo(),
            canRedo : this._sketch.canRedo()
        });
        return this.canvas.renderAll();
    }
    handleRightClickSelect(event){
        event.preventDefault();
        if(this.mapKey['ControlLeft'] === true){
            return this.handleBelowSelected(event);
        }else{
            if(this.state.actualShape !== Tools.Select){
                this.setState({
                    lastShape:this.state.actualShape
                });
                return this.handleChangeTool(Tools.Select);
            }else if(this.state.actualShape !== this.state.lastShape && this.state.lastShape){
                this.handleChangeTool(this.state.lastShape);
                return this.handleBlockedSelected(false);
            }
        }
    }
    handleReloadListStage(){
        return this.setState({
            reloadStage : false
        })
    }
    handleUndo(){
        this.canvas.discardActiveObject();
        this._sketch.undo();
        this.setState({
            selectedElement : false,
            canUndo : this._sketch.canUndo(),
            canRedo : this._sketch.canRedo()
        });
        this.canvas.renderAll();
    }
    handleSketchChange(){
        this.handleSelectedElement();
        const prev = this.state.canUndo;
        const now = this._sketch.canUndo();
        if(prev !== now){
            return this.setState({
                canUndo : now
            });
        }
    }
    handleSelectedChangeColorElement(colorHex){
        const activeObjects = this.canvas.getActiveObjects();
        if(activeObjects.length > 0){
        Object.values(activeObjects).map((value) => {
                if(!value.__originalState || value.__originalState.type === 'i-text'){
                    return value.set('fill', colorHex);
                }
                if(Array.isArray(value._objects)){
                    value._objects.map((valueInside) => {
                        valueInside.set('fill', colorHex);
                        return valueInside.set('stroke', colorHex);
                    })
                }
                return value.set('stroke', colorHex);
            })
            return this.canvas.renderAll();
        }
    }
    handleSelectedElement(){
        let selectedElementValue = Boolean(false);
        let selectedImgValue = Boolean(false);
        const activeObjects = this.canvas.getActiveObjects();
        if(activeObjects.length > 0){
            selectedElementValue = true;
            this.handleSelectedElementPosition(activeObjects);
            Object.values(activeObjects).map((value) => {
                if(value.__originalState && value.__originalState.type === 'image'){
                    selectedImgValue = true;
                }
                return selectedImgValue;
            })
        }else{
            selectedElementValue = false;
        }
        this.setState({
            selectedElement:selectedElementValue,
            selectedImg:selectedImgValue
        })
        this.canvas.renderAll();
        return this.handleChangeTool(this.state.actualShape);
    }
    handleSelectedElementPosition(currentActiveObjects){
        const currentObjects = currentActiveObjects[0];
        let selectedElementTopValue = 0;
        let selectedElementLeftValue = 0;
        let selectedElementWidth = 0;
        if(currentActiveObjects[0].group){
            selectedElementWidth = (currentObjects.group.width * currentObjects.group.scaleX);
            selectedElementTopValue = currentObjects.group.top + (currentObjects.group.height * currentObjects.group.scaleY) + currentObjects.canvas._offset.top + 10;
            selectedElementLeftValue = currentObjects.group.left + selectedElementWidth + currentObjects.canvas._offset.left;
        }
        else{
            selectedElementWidth = (currentObjects.width *  currentObjects.scaleX);
            selectedElementTopValue = currentObjects.top + (currentObjects.height * currentObjects.scaleY) + currentObjects.canvas._offset.top + 10;
            selectedElementLeftValue = currentObjects.left + selectedElementWidth + currentObjects.canvas._offset.left;
        }
        return this.setState({
            selectedElementTop : selectedElementTopValue,
            selectedElementLeft : selectedElementLeftValue,
            selectedElementWidth : selectedElementWidth
        })
        
    }
    handleSendBackwardStage(){
        this.canvas.getActiveObjects().forEach(activeObject => {
            this.canvas.sendBackwards(activeObject);
        })
        this.canvas.discardActiveObject().renderAll();
        return this.handleBlockedSelected(false);
    }
    handleSendBackStage(){
        this.canvas.getActiveObjects().forEach(activeObject => {
            this.canvas.sendToBack(activeObject);
        })
        this.canvas.discardActiveObject().renderAll();
        return this.handleBlockedSelected(false);
    }
    parserParamsShape(paramsShape){
        const paramsShapeParsed = {};
        (Object.keys(paramsShape).map(i => {
            let valueToCheck = Number(paramsShape[i]);
            if(valueToCheck !== 0){
                paramsShapeParsed[i] = valueToCheck;
            }
            return paramsShapeParsed[i];
        }));
        return paramsShapeParsed;
    }
    windowCheckerSceneBuilder(){
        const currentAvaialableHeight = window.innerHeight+'px';
        let isMobile = window.innerWidth < 753 ? true : false;
        this.setState({
            isMobile : isMobile,
            height : currentAvaialableHeight
        });
    }
    render(){
        let toolsSelectedWidth = (this.state.selectedImg !== true) ? 600 : 100;
        let toolsSelectedItemBarStyle = {}
        toolsSelectedItemBarStyle.width = toolsSelectedWidth+'px';
        if(this.state.selectedElement === true){
            if(this.state.isMobile === false){
                toolsSelectedItemBarStyle = {
                    position : 'absolute',
                    top : this.state.selectedElementTop + 'px',
                    left : (this.state.selectedImg !== true) ? this.state.selectedElementLeft - (this.state.selectedElementWidth / 2) - (toolsSelectedWidth / 2) - 50 + 'px' : this.state.selectedElementLeft - toolsSelectedWidth - (this.state.selectedElementWidth / 2) - 30 + 'px'
                }
            }else{
                toolsSelectedItemBarStyle = {
                    position : 'sticky',
                    bottom : '5%',
                };
            }
        }
        return(
                <Container className="container-scenebuilder" 
                    ref={ref => this.refsSceneBuilder = ref}>
                        <Row>
                            <Col className="col-scenebuilder-1">
                                <Row className="rows-tools-bar">
                                    <ToolsButtons
                                        canUndo={this.state.canUndo}
                                        canRedo={this.state.canRedo}
                                        actualShape={this.state.actualShape}
                                        handleAddText={this.handleAddText}
                                        handleChangeTool={this.handleChangeTool}
                                        handleRedo={this.handleRedo}
                                        handleUndo={this.handleUndo}
                                    />
                                </Row>
                            </Col>
                            <Col className="col-scenebuilder-16 mt-5">
                                <StageBuilderIcon className="scenebuilder-logo"/>
                                <Card className="card-scenebuilder">
                                    <Card.Body onDrop ={this.handleDropShape}>
                                        <SketchField width='100%'
                                            ref={(c)=>(this._sketch = c)}
                                            draggable='true'
                                            height={this.state.height}
                                            tool={this.state.actualShape}
                                            lineColor={this.state.lineColor}
                                            lineWidth={3}
                                            onChange={this.handleSketchChange}
                                        />
                                                <Row ref={ref => this.refsScrollElement.current = ref}>
                                                    <FormSceneBuilderDisplay
                                                        toolTip={this.state.toolTip}
                                                        paramsFormStage={this.state.paramsFormStage}
                                                        handleParamsFormRemove={this.handleParamsFormRemove}
                                                    />
                                                </Row>
                                        </Card.Body>
                                </Card>
                            </Col>
                            <Col className="col-scenebuilder-7 col-params-ready-shape mt-5">
                                <ToolsBar
                                    isLogged={this.state.isLogged}
                                    refsScrollElement={this.refsScrollElement}
                                    height={this.state.height}
                                    toolTip={this.state.toolTip}
                                    showSnackBarStageSave={this.state.showSnackBarStageSave}
                                    showModalLogIn={this.state.showModalLogIn}
                                    showModalSaveStage={this.state.showModalSaveStage}
                                    successSnackBarStageSave={this.state.successSnackBarStageSave}
                                    messageSnackBarStageSave={this.state.messageSnackBarStageSave}
                                    showDuplicateStage = {this.state.showDuplicateStage}
                                    paramsFormStageSaved={this.state.paramsFormStageSaved}
                                    reloadStage={this.state.reloadStage}
                                    handleAddImage={this.handleAddImage}
                                    handleAddShape={this.handleAddShape}
                                    handleChangeParamsItem={this.handleChangeParamsItem}
                                    handleClear={this.handleClear}
                                    handleCloseDuplicateStage={this.handleCloseDuplicateStage}
                                    handleLoadStageFromJson={this.handleLoadStageFromJson}
                                    handleReloadListStage={this.handleReloadListStage}
                                    handleSubmitFormSceneBuilder={this.handleSubmitFormSceneBuilder}
                                    handleSaveStage={this.handleSaveStage}
                                    handleSendStage={this.handleSendStage}
                                    handleSetLogIn={this.handleSetLogIn}
                                    handleSetShowModalLogIn={this.handleSetShowModalLogIn}
                                    handleSetShowModalSaveStage={this.handleSetShowModalSaveStage}
                                    handleSetCloseModalLogIn={this.handleSetCloseModalLogIn}
                                    handleSetCloseModalSaveStage={this.handleSetCloseModalSaveStage}
                                    handleSetCloseSnackBarStageSave={this.handleSetCloseSnackBarStageSave}
                                />
                            </Col>
                        </Row>
                        {this.state.selectedElement &&
                            <div className="tools-selected-items" style={toolsSelectedItemBarStyle}>
                                <Row>
                                    <Col className="col-tools-selected-items">
                                        <SelectedItemActionsBar
                                            blockedSelected={this.state.blockedSelected}
                                            toolTip={this.state.toolTip}
                                            handleBringFrontStage={this.handleBringFrontStage}
                                            handleBringForwardStage={this.handleBringForwardStage}
                                            handleClone={this.handleClone}
                                            handleRemoveSelected={this.handleRemoveSelected}
                                            handleSendBackwardStage={this.handleSendBackwardStage}
                                            handleSendBackStage={this.handleSendBackStage}
                                        />
                                    </Col>
                                    {!this.state.selectedImg &&
                                        <Col className="col-tools-selected-items">
                                            <ColorBar 
                                                handleSelectedChangeColorElement={this.handleSelectedChangeColorElement}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </div>
                        }
                </Container>
        )
    }
}
export default StageBuilder;