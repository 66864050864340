import React from 'react';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {ReactComponent as DeleteParamsIcon } from './icons/DeleteParamsIcon.svg';

class FormSceneBuilderDisplay extends React.Component{
    constructor(props){
        super(props);
        
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        
        this.state = {
            dictionary : {
                roundAmount : 'Round Count',
                stageAmount : 'Stage',
                stringsAmount : 'Strings',
                paperAmount : 'Paper Targets',
                startPosition : 'Start Position',
                steelAmount : 'Steel',
                stageName : 'Stage name',
                stageKind : 'Scoring',
                stageType : 'Stage type',
                stageSetupNotes : 'Setup notes',
                procedure : 'Procedure'
            }
        }

        this.refsDeleteParamsIcon = React.createRef([]);
    }
    handleMouseOver(){
        this.refsDeleteParamsIcon.current.childNodes[0].style.backgroundColor = '#CE2523';
        return this.refsDeleteParamsIcon.current.childNodes[0].childNodes[0].childNodes[2].style.fill = '#ffffff';
    }   
    handleMouseOut(){
        this.refsDeleteParamsIcon.current.childNodes[0].style.backgroundColor = '#F2F2F2';
        return this.refsDeleteParamsIcon.current.childNodes[0].childNodes[0].childNodes[2].style.fill = '#686868';
    }
    renderParameters(){
        return(
            <Row>
                <Col lg={4} md={4} xs={4}>
                    {Object.keys(this.props.paramsFormStage).filter((i) => i !== 'stageSetupNotes' && i !== 'procedure').map(i => {
                        return (
                            <Row key={i} className="mt-2">
                                <Col className="head-parameter">
                                    {this.state.dictionary[i]}
                                </Col>
                                <Col>
                                    {this.props.paramsFormStage[i]}
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
                {this.props.paramsFormStage['procedure'] &&
                    <Col lg={4} md={4} xs={4}>
                        <Row key='labelProcedure'>
                            <Col className="head-parameter">
                                {this.state.dictionary['procedure']}
                            </Col>
                        </Row>
                        <Row key='textProcedure' className="mt-3">
                            <Col>
                                {this.props.paramsFormStage['procedure']}
                            </Col>
                        </Row>
                    </Col>
                }
                {this.props.paramsFormStage['stageSetupNotes'] &&
                    <Col lg={4} md={4} xs={4}>
                        <Row key='labelStageSetupNotes'>
                            <Col className="head-parameter">
                                {this.state.dictionary['stageSetupNotes']}
                            </Col>
                        </Row>
                        <Row key='textStageSetupNotes' className="mt-3">
                            <Col>
                                {this.props.paramsFormStage['stageSetupNotes']}
                            </Col>
                        </Row>
                    </Col>
                }  
            </Row>
        );
    }
    render(){
        let checkParamsFormStage = false;
        if(this.props.paramsFormStage && Object.keys(this.props.paramsFormStage).length > 0){
            checkParamsFormStage = true;
        }
        return(
            <Col xs={12}>
                {checkParamsFormStage !== false && 
                    <h3>
                        Your stage parameters
                    </h3>
                }   
                    <div className="container-parameters">
                        {checkParamsFormStage !== false &&
                            <>
                                <div
                                    ref={el => this.refsDeleteParamsIcon.current = el}
                                    onMouseOver={()=>this.handleMouseOver()} 
                                    onMouseOut={()=>this.handleMouseOut()}
                                >
                                        <OverlayTrigger
                                            trigger={this.props.toolTip.trigger}
                                            placement='bottom'
                                            delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                            overlay={<Tooltip>remove description</Tooltip>}
                                        >
                                                <button className="button-params-remove"
                                                    onClick={()=>this.props.handleParamsFormRemove()}
                                                >  
                                                        <DeleteParamsIcon />
                                                </button>
                                        </OverlayTrigger>
                                </div>
                                <div className="mt-2">
                                    {this.renderParameters()}
                                </div>
                            </>
                        }
                    </div>
            </Col>
        )
    }
}
export default FormSceneBuilderDisplay;