import React from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import Modal from '@material-ui/core/Modal';
import {ReactComponent as RemoveStageDarkIcon} from './icons/RemoveStageDarkIcon.svg';

class ModalConfirmRemove extends React.Component{
    render(){
        return(
            <>
                <Modal className="modal-params-backdrop"
                    open={this.props.showModalConfirmRemove}
                    onClose={this.props.handleSetCloseModalConfirmRemove}
                >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-params w-100">
                            <div className="modal-content modal-content-scroll">
                                <div className="params-header modal-header">
                                    <div className="modal-title h4">
                                        <RemoveStageDarkIcon />
                                        <br />
                                        DELETE STAGE
                                    </div>
                                    <button type="button" className="close" onClick={this.props.handleSetCloseModalConfirmRemove}>
                                        <span aria-hidden="true">×</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-sub-title">
                                    Please confirm that you really want to <b>delete the whole scene</b>. You've worked hard on it.
                                </div>
                                <Container className="w-75 mt-5 mb-5">
                                    <Row>
                                        <Col md={6} lg={6}>
                                            <Button variant="danger" className="modal-button-save w-100" onClick={this.props.handleRemoveStage}>
                                                YES, DELETE
                                            </Button>
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Button variant="danger" className="modal-button-discard w-100" onClick={this.props.handleSetCloseModalConfirmRemove}>
                                                NO, I CHANGED MY MIND
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                </Modal>
            </>
        )
    }
}
export default ModalConfirmRemove;