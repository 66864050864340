import React from 'react';
import {LoadShapes} from './ModelStage';
import {Card, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import {ReactComponent as AddImgIcon} from './icons/AddImgIcon.svg';
import {ReactComponent as DragIcon} from './icons/DragIcon.svg';

class ReadyShapes extends React.Component{
    constructor(props){
        super(props);
        
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleDragShapeStart = this.handleDragShapeStart.bind(this);
        this.handleLoadShapes = this.handleLoadShapes.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        
        this.state = {
            icons : []
        }

        this.refsDragShape = React.createRef();
        this.refsDragShape.current = [];
    }
    async componentDidMount(){
        return this.handleLoadShapes();
    }
    handleGetBase64Image(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
    handleAddImage(event){
        if(event.target.files && event.target.files[0]){
            this.handleGetBase64Image(event.target.files[0]).then(
                base64Image => {
                    return this.props.handleAddImage(base64Image, {scale:this.state.scale})
                }
            );
        }
    }
    handleAddShape(indexShape, topPosition, leftPosition, sizeShape){
        const paramsShape = {
            top:topPosition,
            left:leftPosition,
            scale:sizeShape
        };
        return this.props.handleAddShape(this.helperGetNameShape(indexShape), paramsShape);
    }
    async handleLoadShapes(){
        LoadShapes().then(result => {
            this.setState({
                icons : result
            });
            setTimeout(() => {
                const imageShape = document.getElementsByClassName('img-ready-shapes');
                for(let i = 0; i < imageShape.length; i++){
                    imageShape[i].addEventListener("dragstart", function(e) {
                        let dragShape = document.createElement("img");
                        dragShape.src = this.src;
                        let scale = Number(this.getAttribute('scale'));
                        let width = dragShape.width * scale;
                        let height = dragShape.height * scale;
                        dragShape.style.width = width + 'px';
                        dragShape.style.height = height + 'px';
                        dragShape.setAttribute('class', 'draggable-element');
                        document.querySelector('body').appendChild(dragShape);
                        e.dataTransfer.setDragImage(dragShape, width / 2, height / 2);
                    }, false);
                }
            }, 0);
            document.addEventListener("drop", function(event) {
                const imageDraggable = document.getElementsByClassName('draggable-element');
                for(let i = 0; i < imageDraggable.length; i++){
                    imageDraggable[i].parentNode.removeChild(imageDraggable[i]);
                }
            });
        })
    }
    handleDragShapeStart(indexShape, sizeShape){
        window.localStorage.setItem('dragShape', this.helperGetNameShape(indexShape));
        return window.localStorage.setItem('scale', sizeShape);
    }
    helperGetNameShape(indexShape){
        return this.state.icons[indexShape].icon;
    }
    handleRenderWidthSize(url){
        const image = document.createElement("img");
        image.src = url;
        return image.width;
    }
    handleMouseOver(indexShape){
        this.refsDragShape.current[indexShape].style.backgroundColor = '#313131';
        return this.refsDragShape.current[indexShape].childNodes[0].style.opacity = 1;
    }   
    handleMouseOut(indexShape){
        this.refsDragShape.current[indexShape].style.backgroundColor = '#484848';
        return this.refsDragShape.current[indexShape].childNodes[0].style.opacity = 0;
    }
    importImages(val){
        return val.keys().map(val);
    }
    render(){
        return (
            <Row>
                <Col md>
                    <Row className="row-no-margin">
                        <h5 className="ready-shapes-title">READY TO USE PROPS</h5>
                        <Row noGutters={true}>
                            <Col lg={3} md={6} xs={12} className="col-image-button">
                                <IconButton className="add-image-button" 
                                    onClick={() => this.uploadFile.click()}>
                                        <AddImgIcon />
                                </IconButton>
                                <input type="file" 
                                    ref={(uploadFile)=>{this.uploadFile=uploadFile}} 
                                    style={{visibility:'hidden'}} 
                                    onChange={this.handleAddImage}/>
                            </Col>
                            {Object.keys(this.state.icons).map(i => (
                                <Col key={i} lg={3} md={6} xs={12} className="col-image-button" 
                                    ref={(ref)=>this.refsDragShape.current[i]=ref} 
                                    onMouseOver={()=>this.handleMouseOver(i)} 
                                    onMouseOut={()=>this.handleMouseOut(i)}>
                                        {this.state.icons[i].toolTip ? (
                                            <>
                                                <DragIcon className="icon-draggable"/>
                                                    <OverlayTrigger
                                                        trigger={this.props.toolTip.trigger}
                                                        placement={this.props.toolTip.placement}
                                                        delay={{show: this.props.toolTip.show, hide: this.props.toolTip.hide}}
                                                        overlay={<Tooltip>{this.state.icons[i].toolTip}</Tooltip>}
                                                    >
                                                            <Card.Img className="img-ready-shapes"
                                                                draggable="true"  
                                                                src={this.state.icons[i].icon} 
                                                                onDragStart={()=>this.handleDragShapeStart(i, this.state.icons[i].sizeShape)} 
                                                                scale={this.state.icons[i].sizeShape}
                                                                width={this.handleRenderWidthSize(this.state.icons[i].icon)}
                                                                />
                                                    </OverlayTrigger>
                                            </>
                                        ) : (
                                            <>
                                                <DragIcon className="icon-draggable"/>
                                                <Card.Img className="img-ready-shapes" 
                                                    draggable="true" 
                                                    src={this.state.icons[i].icon} 
                                                    onDragStart={()=>this.handleDragShapeStart(i, this.state.icons[i].sizeShape)} 
                                                    scale={this.state.icons[i].sizeShape}
                                                    width={this.handleRenderWidthSize(this.state.icons[i].icon)}
                                                />
                                            </>
                                        )}
                                </Col>
                            ))}
                        </Row>
                    </Row>
                </Col>
            </Row>
        )
    }
}
export default ReadyShapes;