import React from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import FormRegister from './FormRegister';
import FormPasswordRecovery from './FormPasswordRecovery';
import {LogIn} from './ModelStage';
import Modal from '@material-ui/core/Modal';
import {ReactComponent as AccountDarkIcon} from './icons/AccountDarkIcon.svg';
import SnackBarMessage from './SnackBarMessage';

class ModalLogIn extends React.Component{
    constructor(props){
        super(props);
        
        this.handleSetCloseSnackBarLogIn = this.handleSetCloseSnackBarLogIn.bind(this);
        this.handleSetCloseModalLogIn = this.handleSetCloseModalLogIn.bind(this);
        this.handleSetShowSnackBarLogin = this.handleSetShowSnackBarLogin.bind(this);
        this.handleSetSendCode = this.handleSetSendCode.bind(this);
        this.handleSetLastEmail = this.handleSetLastEmail.bind(this);
        this.handleSubmitFormLogIn = this.handleSubmitFormLogIn.bind(this);
        this.handleShowFormPasswordRecovery = this.handleShowFormPasswordRecovery.bind(this);

        this.state = {
            countRecoveryPassword : 0,
            formRegister : false,
            formPasswordRecovery : false,
            messageSnackBarLogIn : '',
            isShowForm : true,
            isSendCode : false,
            showSnackBarLogIn : false,
            successSnackBarLogIn : false,
            lastEmail : ''
        }

        this.refLoginOrEmail = React.createRef();
        this.refPassword = React.createRef();
        this.refForm = React.createRef();
    }
    handleSetCloseSnackBarLogIn(){
        this.setState({
            showSnackBarLogIn : false
        });
        if(this.state.successSnackBarLogIn === true){
            this.setState({
                formRegister : false,
                formPasswordRecovery : this.state.isSendCode === true ? true : false,
                isShowForm : true
            });
            if(this.state.isSendCode !== true){
                this.props.handleSetCloseModalLogIn();
            }
        }else{
            return true;
        }
    }
    handleSetSendCode(boolValue){
        return this.setState({
            isSendCode : boolValue
        });
    }
    handleSetCloseModalLogIn(){
            this.setState({
                formRegister : false,
                formPasswordRecovery : false
            });
        return this.props.handleSetCloseModalLogIn();
    }
    handleSetShowSnackBarLogin(success, show, message, isShowForm){
        return this.setState({
            successSnackBarLogIn : success,
            showSnackBarLogIn : show,
            messageSnackBarLogIn : message,
            isShowForm : isShowForm
        });
    }
    async handleShowFormRegister(event){
        event.preventDefault();
        event.stopPropagation();
        let currentValue = this.state.formRegister
        await this.setState({
            formRegister : !currentValue,
            formPasswordRecovery : false
        });
        if(!currentValue === true){
            return this.refForm.current.scrollIntoView();
        }
        return true;
    }
    handleShowFormPasswordRecovery(event){
        event.preventDefault();
        event.stopPropagation();
        let currentValue = this.state.formPasswordRecovery;
        if(this.state.isSendCode === true){
            this.setState({
                countRecoveryPassword : this.state.countRecoveryPassword + 1,
            });
        }
        return this.setState({
            formPasswordRecovery: !currentValue,
            formRegister : false
        });
        
    }
    handleSubmitFormLogIn(event){
        event.preventDefault();
        event.stopPropagation();
        const loginOrEmail = this.refLoginOrEmail.current.value;
        const password =  this.refPassword.current.value;
        LogIn(loginOrEmail, password).then(result => {
            if(result === true){
                this.setState({
                    successSnackBarLogIn : true,
                    showSnackBarLogIn : true,
                    messageSnackBarLogIn : 'You have been logged in',
                    isShowForm : false,
                    isSendCode : false
                })
            }
            else{
                this.setState({
                    successSnackBarLogIn : false,
                    showSnackBarLogIn : true,
                    messageSnackBarLogIn : 'Wrong password or login'                        
                })
            }
            this.props.handleSetLogIn(result);
        })
    }
    handleSetLastEmail(emailValue){
        return this.setState({
            lastEmail : emailValue
        });
    }
    render(){
        return(
            <Modal className="modal-params-backdrop"
                open={this.props.showModalLogIn}
                onClose={this.handleSetCloseModalLogIn}
            >
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-params w-100">
                        <div className="modal-content modal-content-scroll">
                            <div className="params-header modal-header">
                                <div className="modal-title h4">
                                    <AccountDarkIcon />
                                        <br />
                                        LOG IN
                                </div>
                                <button type="button" className="close" onClick={this.handleSetCloseModalLogIn}>
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="modal-sub-title">
                                Log in into your <b>TargetBarn's The Broad Side</b> account.
                            </div>
                            <div className="modal-body">
                                <Container className="container-modal-params">
                                    <SnackBarMessage
                                        show={this.state.showSnackBarLogIn}
                                        success={this.state.successSnackBarLogIn}
                                        message={this.state.messageSnackBarLogIn}
                                        handleSetCloseSnackBar={this.handleSetCloseSnackBarLogIn}
                                    />
                                    {this.state.isShowForm === true && 
                                        <Form onSubmit={this.handleSubmitFormLogIn}>
                                            <Row className="h-100 row-params">
                                                <Col>
                                                    <Form.Group as={Row} className="mb-2" controlId="logOrEmail">
                                                        <Form.Label column md="5" className="label-params p-0">
                                                            LOGIN/EMAIL
                                                        </Form.Label>
                                                        <Col md="7" className="form-control-input">
                                                            <Form.Control type="text" autoComplete="on" name="logOrEmail" className="input-form-params" maxLength={50} placeholder="Your login/email" ref={ref => this.refLoginOrEmail.current = ref}/>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-2" controlId="password">
                                                        <Form.Label column md="5" className="label-params p-0">
                                                            PASSWORD
                                                        </Form.Label>
                                                        <Col md="7" className="form-control-input">
                                                            <Form.Control type="password" autoComplete="current-password" name="password" className="input-form-params" maxLength={50} placeholder="Your password" ref={ref => this.refPassword.current = ref}/>
                                                        </Col>
                                                    </Form.Group>
                                                    <Row>
                                                        <Col>
                                                            <Button type="submit" variant="danger" className="submit-button-form float-right">LOGIN</Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="ml-3">
                                                    <p className="mb-1">
                                                        You'll gain
                                                    </p>
                                                    <ul className="adventages-register">
                                                        <li>
                                                            Access to recently created stages
                                                        </li>
                                                        <li>
                                                            Save your efforts in the Stage Builder's cloud
                                                        </li>
                                                        <li>
                                                            New Updates from the Broad Side Blog
                                                        </li>
                                                        <li>
                                                            Weekly specials at Target Barn
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Form>
                                    }
                                </Container>
                            </div>
                            {this.state.isShowForm === true &&
                                <div className="modal-sub-title mb-2">
                                    New to Broad Side? <a href="!#" onClick={(e) => this.handleShowFormRegister(e)} className="modal-stage-link">Create an account</a> to always make the best shot.<br />
                                    <p className="small-adjustment">If you don't remember your password, <a href="!#"  onClick={this.handleShowFormPasswordRecovery} className="modal-stage-link">you can recover it here.</a></p>
                                </div>
                            }
                            {this.state.formRegister === true && this.state.isShowForm === true &&
                                <div className="modal-body" ref={ref => this.refForm.current = ref}>
                                    <FormRegister
                                        handleSetLogIn={this.props.handleSetLogIn}
                                        handleSetShowSnackBarLogin={this.handleSetShowSnackBarLogin}
                                    />
                                </div>
                            }
                            {this.state.formPasswordRecovery === true && this.state.isShowForm === true &&
                                <div className="modal-body">
                                    <FormPasswordRecovery
                                        countRecoveryPassword={this.state.countRecoveryPassword}
                                        isSendCode={this.state.isSendCode}
                                        lastEmail={this.state.lastEmail}
                                        handleSetShowSnackBarLogin={this.handleSetShowSnackBarLogin}
                                        handleSetSendCode={this.handleSetSendCode}
                                        handleSetLastEmail={this.handleSetLastEmail}
                                    />
                                </div>
                            }
                        </div>
                    </div>
            </Modal>
        )
    }
}
export default ModalLogIn;