import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ModalConfirmRemove from './ModalConfirmRemove';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {ReactComponent as LinkStageIcon} from './icons/LinkStageIcon.svg';
import {ReactComponent as RemoveStageDarkIcon} from './icons/RemoveStageDarkIcon.svg';
import {GetListStage, GetStage, RemoveStage} from './ModelStage.js';
import {TablePagination} from '@mui/material';
import SnackBarMessage from './SnackBarMessage';

class ListStage extends React.Component{
    constructor(props){
        super(props);
        
        this.handleListStages = this.handleListStages.bind(this);
        this.handleLoadStage = this.handleLoadStage.bind(this);
        this.handleRemoveStage = this.handleRemoveStage.bind(this);
        this.handleSetShowModalConfirmRemove = this.handleSetShowModalConfirmRemove.bind(this);
        this.handleSetCloseModalConfirmRemove = this.handleSetCloseModalConfirmRemove.bind(this);
        this.handleSetCloseSnackBarStageRemove = this.handleSetCloseSnackBarStageRemove.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this); 
        
        this.state = {
            currentIdStage : 0,
            limit : this.props.recentlyStage ? 5 : 10,
            messageSnackBarStageRemove : '',
            offset : 0,
            page : 0,
            stageCount : 0,
            stageList : {},
            showModalConfirmRemove : false,
            showSnackBarStageRemove : false,
            successSnackBarStageRemove : false,
            toolTip : {
                trigger : ['hover', 'hover'],
                placement : 'top',
                show : 650,
                hide : 50
            }
        }

        this.handleListStages();
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.limit !== this.state.limit || prevState.offset !== this.state.offset){
            return this.handleListStages();
        }
        if(this.props.reloadStage === true){
            this.handleListStages();
            return this.props.handleReloadListStage();
        }
    }
    componentDidMount(){
        this.handleListStages();
    }
    handleChangeRowsPerPage(event, rowsPerPage){
        event.preventDefault();
        event.stopPropagation();
        return this.setState({
            limit : rowsPerPage.props.value
        });
    }
    handleListStages(){
        GetListStage(this.state.limit, this.state.offset).then(result => {
            return this.setState({
                stageList : result.stage_list,
                stageCount : result.stage_count
            })
        })
    }
    handleLoadStage(idStage){
        GetStage(idStage).then(result => {
            if(result.success === true){
                this.props.handleLoadStageFromJson(result.body_stage);
                return this.props.handleSetCloseModalLoadStage();
            }
        })
    }
    handlePageChange(event, newPage){
        event.preventDefault();
        event.stopPropagation();
        let offset = newPage * this.state.limit
        this.setState({
            page : newPage,
            offset : offset
        });
    }
    handleSetCloseModalConfirmRemove(){
        this.setState({
            showModalConfirmRemove : false,
            currentIdStage: 0
        });
        document.getElementsByClassName('modal-content')[0].hidden = false;
    }
    handleSetShowModalConfirmRemove(idStage){
        document.getElementsByClassName('modal-content')[0].hidden = true;
        return this.setState({
            showModalConfirmRemove : true,
            currentIdStage: idStage
        });
    }
    handleRemoveStage(){
        RemoveStage(this.state.currentIdStage).then(result => {
            if(result === true){
                this.handleListStages();
                this.handleSetCloseModalConfirmRemove();
                return this.setState({
                    successSnackBarStageRemove : true,
                    showSnackBarStageRemove : true,
                    messageSnackBarStageRemove : 'The stage has been deleted'
                })
            }else{
                return this.setState({
                    successSnackBarStageRemove : false,
                    showSnackBarStageRemove : true,
                    messageSnackBarStageRemove : 'The stage has not been deleted'
                })
            }
        })
    }
    handleSetCloseSnackBarStageRemove(){
        this.setState({
            showSnackBarStageRemove : false
        });
    }
    render(){
        return(
            <>
                <SnackBarMessage 
                     show={this.state.showSnackBarStageRemove}
                     success={this.state.successSnackBarStageRemove}
                     message={this.state.messageSnackBarStageRemove}
                     handleSetCloseSnackBar={this.handleSetCloseSnackBarStageRemove}
                />
                <div className="scroll-table-stage">
                    <table className="table table-stage mt-5">
                        <tbody>
                            {Object.values(this.state.stageList).map((value) => (
                                <tr key={value.id}>
                                    <td style={{width:'25%'}}>
                                        <img src={value.screen_stage} width="auto" height="100rem" alt={value.name_stage}/>
                                    </td>
                                    <td style={{width:'40%'}} className="align-middle">
                                        <OverlayTrigger
                                            trigger={this.state.toolTip.trigger}
                                            placement={this.state.toolTip.placement}
                                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                                            overlay={<Tooltip>Open</Tooltip>}
                                        >
                                            <a href="!#" onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.handleLoadStage(value.id)}
                                            }>
                                                {value.name_stage}
                                            </a>
                                        </OverlayTrigger>
                                    </td>
                                    <td style={{width:'20%'}} className="align-middle">
                                        <p className="date-save-stage">
                                            {value.date_stage}
                                        </p>
                                    </td>
                                    <td style={{width:'5%'}} className="align-middle">
                                        <OverlayTrigger
                                            trigger={this.state.toolTip.trigger}
                                            placement={this.state.toolTip.placement}
                                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                                            overlay={<Tooltip>Open</Tooltip>}
                                        >
                                            <IconButton
                                                onClick={()=>this.handleLoadStage(value.id)}>
                                                    <LinkStageIcon />
                                            </IconButton>
                                        </OverlayTrigger>
                                    </td>
                                    <td style={{width:'5%'}} className="align-middle">
                                        <OverlayTrigger
                                            trigger={this.state.toolTip.trigger}
                                            placement={this.state.toolTip.placement}
                                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                                            overlay={<Tooltip>Delete</Tooltip>}
                                        >
                                            <IconButton
                                                onClick={()=>this.handleSetShowModalConfirmRemove(value.id)}>
                                                    <RemoveStageDarkIcon/>
                                            </IconButton>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={this.state.stageCount}
                        rowsPerPage={this.state.limit}
                        page={this.state.page}
                        onPageChange={this.handlePageChange}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
                <ModalConfirmRemove
                    showModalConfirmRemove={this.state.showModalConfirmRemove}
                    handleSetCloseModalConfirmRemove={this.handleSetCloseModalConfirmRemove}
                    handleRemoveStage={this.handleRemoveStage}
                />
            </>
        )
    }
}
export default ListStage;