import React from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import Modal from '@material-ui/core/Modal';
import { ReactComponent as AddDescriptionDarkIcon } from './icons/AddDescriptionDarkIcon.svg';

class ModalFormSceneBuilderParams extends React.Component{
    constructor(props){
        super(props);
        
        this.handleChangeParamsItem = this.handleChangeParamsItem.bind(this);
        this.handleCustomInValidateNumber = this.handleCustomInValidateNumber.bind(this);
        this.handleSubmitFormSceneBuilder = this.handleSubmitFormSceneBuilder.bind(this);
    }
    handleChangeParamsItem(event){
        event.target.setCustomValidity('');
        return this.props.handleChangeParamsItem(event.target.name, event.target.value);
    }
    handleCustomInValidateNumber(event){
        const currentValue = event.target.value;
        if(isNaN(currentValue) === true || currentValue.length === 0){
            return event.target.setCustomValidity('Please enter a number');
        }else if(currentValue < 0){
            return event.target.setCustomValidity('Value must be greater than or equal to zero');
        }
    }
    handleSubmitFormSceneBuilder(event){
        event.preventDefault();
        return this.props.handleSubmitFormSceneBuilder();
    }
    render(){
        return(
            <Modal className="modal-params-backdrop"
                open={this.props.showModalForm}
                onClose={this.props.handleSetCloseFormSceneBuilderParams}
            >
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-params w-100">
                        <div className="modal-content">
                            <div className="params-header modal-header">
                                <div className="modal-title h4">
                                    <AddDescriptionDarkIcon />
                                    <br />
                                    ADD DESCRIPTION TO STAGE
                                </div>
                                <button type="button" className="close" onClick={this.props.handleSetCloseFormSceneBuilderParams}>
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Container className="container-modal-params">
                                    <Form onSubmit={this.handleSubmitFormSceneBuilder}>
                                        <Row className="h-100 row-params">
                                            <Col>
                                                <Form.Group as={Row} className="mb-3" controlId="stageName">
                                                    <Form.Label column xs="4" className="label-params">
                                                        STAGE NAME
                                                    </Form.Label>
                                                    <Col xs="8" className="form-control-input">
                                                        <Form.Control type="text" name="stageName" className="input-form-params" maxLength={50} defaultValue={this.props.paramsFormStageSaved.stageName} placeholder="Please write your stage name" onChange={this.handleChangeParamsItem}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="startPosition">
                                                    <Form.Label column xs="4" className="label-params">
                                                        START POSITION
                                                    </Form.Label>
                                                    <Col xs="8" className="form-control-input">
                                                        <Form.Control type="text" name="startPosition" className="input-form-params" maxLength={50} defaultValue={this.props.paramsFormStageSaved.startPosition} placeholder="Please write your start position" onChange={this.handleChangeParamsItem}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="stageAmount">
                                                    <Form.Label column xs="4" className="label-params">
                                                        STAGE #
                                                    </Form.Label>
                                                    <Col xs="8" className="form-control-input">
                                                        <Form.Control type="number" name="stageAmount" className="input-form-params" min={0} defaultValue={this.props.paramsFormStageSaved.stageAmount ? Number(this.props.paramsFormStageSaved.stageAmount) : 0} onChange={this.handleChangeParamsItem} onInvalid={this.handleCustomInValidateNumber}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="roundAmount">
                                                    <Form.Label column xs="4" className="label-params">
                                                        ROUND COUNT
                                                    </Form.Label>
                                                    <Col xs="8" className="form-control-input">
                                                        <Form.Control type="number" name="roundAmount" className="input-form-params" min={0} defaultValue={this.props.paramsFormStageSaved.roundAmount ? Number(this.props.paramsFormStageSaved.roundAmount) : 0} onChange={this.handleChangeParamsItem} onInvalid={this.handleCustomInValidateNumber}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="stringsAmount">
                                                    <Form.Label column xs="4" className="label-params">
                                                        STRINGS
                                                    </Form.Label>
                                                    <Col xs="8" className="form-control-input">
                                                        <Form.Control type="number" name="stringsAmount" className="input-form-params" min={0} defaultValue={this.props.paramsFormStageSaved.stringsAmount ? Number(this.props.paramsFormStageSaved.stringsAmount) : 0} onChange={this.handleChangeParamsItem} onInvalid={this.handleCustomInValidateNumber}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="paperAmount">
                                                    <Form.Label column xs="4" className="label-params">
                                                        PAPER TARGETS
                                                    </Form.Label>
                                                    <Col xs="8" className="form-control-input">
                                                        <Form.Control type="number" name="paperAmount" className="input-form-params" min={0} defaultValue={this.props.paramsFormStageSaved.paperAmount ? Number(this.props.paramsFormStageSaved.paperAmount) : 0} onChange={this.handleChangeParamsItem} onInvalid={this.handleCustomInValidateNumber}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="steelAmount">
                                                    <Form.Label column xs="4" className="label-params">
                                                        STEEL
                                                    </Form.Label>
                                                    <Col xs="8" className="form-control-input">
                                                        <Form.Control type="number" name="steelAmount" className="input-form-params" min={0} defaultValue={this.props.paramsFormStageSaved.steelAmount ? Number(this.props.paramsFormStageSaved.steelAmount) : 0} onChange={this.handleChangeParamsItem} onInvalid={this.handleCustomInValidateNumber}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="stageKind">
                                                    <Form.Label column xs="4" className="label-params">
                                                        SCORING
                                                    </Form.Label>
                                                    <Col xs="8" className="form-control-input">
                                                        <Form.Control name="stageKind" className="input-form-params" as="select" defaultValue={!this.props.paramsFormStageSaved.stageKind ? 'default' : this.props.paramsFormStageSaved.stageKind} onChange={this.handleChangeParamsItem}>
                                                            {!this.props.paramsFormStageSaved.stageKind ?
                                                                <option value="default" disabled hidden>Please Select a stage kind</option> 
                                                            :
                                                                <option value={this.props.paramsFormStageSaved.stageKind} hidden>{this.props.paramsFormStageSaved.stageKind}</option>
                                                            }           
                                                            <option value='Comstock'>Comstock</option>
                                                            <option value='Virginia'>Virginia</option>
                                                            <option value='Fixed Time'>Fixed Time</option>
                                                            <option value='Vickers Ct'>Vickers Ct</option>
                                                            <option value='Ltd Vickers'>Ltd Vickers</option>
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="procedure">
                                                    <Form.Label className="label-params label-textarea">
                                                        PROCEDURE
                                                    </Form.Label>
                                                <Col>
                                                    <Form.Control name="procedure" className="input-form-params" as="textarea" rows={4} maxLength={350} defaultValue={this.props.paramsFormStageSaved.procedure ? this.props.paramsFormStageSaved.procedure : ''} placeholder="Please write your procedure" onChange={this.handleChangeParamsItem}/>
                                                </Col>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="stageSetupNotes">
                                                    <Form.Label className="label-params label-textarea">
                                                        SETUP NOTES
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control name="stageSetupNotes" className="input-form-params" as="textarea" rows={4} maxLength={350} defaultValue={this.props.paramsFormStageSaved.stageSetupNotes ? this.props.paramsFormStageSaved.stageSetupNotes : ''} placeholder="Please write your setup notes" onChange={this.handleChangeParamsItem}/>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mt-5">
                                            <Col>
                                                <Button type="submit" variant="danger" className="submit-button-form">ADD DESCRIPTION</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Container>
                            </div>
                        </div>
                      </div>
                </Modal>
        )
    }
}
export default ModalFormSceneBuilderParams;