import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {ReactComponent as CheckErrorIcon} from './icons/CheckErrorIcon.svg';
import {ReactComponent as CheckSuccessIcon} from './icons/CheckSuccessIcon.svg';

class SnackBarMessage extends React.Component{
    constructor(props){
        super(props);
        
        this.handleCheckerViewSnackBar = this.handleCheckerViewSnackBar.bind(this);
    }
    async componentDidUpdate(){
        if(this.props.show === true){
            return this.handleCheckerViewSnackBar();
        }
    }
    handleCheckerViewSnackBar(){
        let delayValue = this.props.success === true ? 2500 : 3000;
        setTimeout(function () {
            return this.props.handleSetCloseSnackBar();
        }.bind(this), delayValue);
    }
    render(){
        const classSnackbar = [
            'stage-snackbar', 
            'w-100', 
            this.props.success === true? 'success-snackbar' : 'error-snackbar', 
            this.props.show === true ? 'show-snackbar' : 'hidden-snackbar'
          ].join(' ');          
        return(
            <Container className={classSnackbar}>
                <Row className="h-100 d-flex align-items-center">
                    <Col xs={1} lg={1} md={1}>
                        {this.props.success === true 
                            ?
                                <CheckSuccessIcon />
                            :
                                <CheckErrorIcon />
                        }
                    </Col>
                    <Col xs={11} lg={11} md={11}>
                        {this.props.message}
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default SnackBarMessage;