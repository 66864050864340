import React from 'react';
import {Button, Container} from 'react-bootstrap';
import ListStage from './ListStage';
import {LogOut} from './ModelStage';
import Modal from '@material-ui/core/Modal';
import {ReactComponent as ListStageDarkIcon} from './icons/ListStageDarkIcon.svg';
import {ReactComponent as AccountDarkIcon} from './icons/AccountDarkIcon.svg';
import SnackBarMessage from './SnackBarMessage';

class ModalLogOut extends React.Component{
    constructor(props){
        super(props);
        
        this.handleLogOut = this.handleLogOut.bind(this);
        this.handleSetCloseSnackBarLogOut = this.handleSetCloseSnackBarLogOut.bind(this);
        
        this.state = {
            messageSnackBarLogOut : '',
            showSnackBarLogOut : false,
            successSnackBarLogOut : true
        };
    }
    handleLogOut(){
        LogOut().then(result => {
            if(result === true){
                this.props.handleSetLogIn(false);
                return this.setState({
                    showSnackBarLogOut : true,
                    messageSnackBarLogOut : 'You have been logged out'
                })
            }
        })
    }
    handleSetCloseSnackBarLogOut(){
        this.setState({
            showSnackBarLogOut : false
        });
        return this.props.handleSetCloseModalLogOut();
    }
    render(){
        return(
            <Modal className="modal-params-backdrop"
                open={this.props.showModalLogOut}
                onClose={this.props.handleSetCloseModalLogOut}
            >
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-params w-100">
                        <div className="modal-content modal-content-scroll">
                            <Container className="w-100">
                                <div className="params-header modal-header">
                                    <div className="modal-title h4">
                                        <AccountDarkIcon />
                                        <br />
                                        YOUR ACCOUNT
                                    </div>
                                    <button type="button" className="close" onClick={this.props.handleSetCloseModalLogOut}>
                                        <span aria-hidden="true">×</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-sub-title mb-5">
                                    <b>{localStorage.getItem('sb_login')}</b> you can search list below for recently saved stages or just log out.
                                    <Button variant="danger" className="modal-button-log-out" onClick={this.handleLogOut}>
                                        LOG OUT
                                    </Button>
                                </div>
                                <SnackBarMessage
                                        message={this.state.messageSnackBarLogOut}
                                        show={this.state.showSnackBarLogOut}
                                        success={this.state.successSnackBarLogOut}
                                        handleSetCloseSnackBar={this.handleSetCloseSnackBarLogOut}
                                    />
                                <div className="modal-title h4">
                                    <ListStageDarkIcon />
                                    <br />
                                    MY STAGES
                                </div>
                                <div className="modal-sub-title">
                                    Here is a list of all recently saved stages.
                                </div>
                                <div className="modal-body">
                                    <Container className="pl-5 pr-5 w-100">
                                        <ListStage
                                            recentlyStage={true}
                                            handleLoadStageFromJson={this.props.handleLoadStageFromJson}
                                            handleSetCloseModalLoadStage={this.props.handleSetCloseModalLogOut}
                                            handleSetShowLastModal={this.props.handleSetShowLastModal}
                                            handleSetCloseLastModal={this.props.handleSetCloseLastModal}
                                        />
                                    </Container>
                                </div>
                            </Container>
                        </div>
                    </div>
            </Modal>
        )
    }
}
export default ModalLogOut;