import React from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {RegisterUser} from './ModelStage';

class FormRegister extends React.Component{
    constructor(props){
        super(props);
        
        this.handleChangeFormRegister = this.handleChangeFormRegister.bind(this);
        this.handleSubmitFormRegister = this.handleSubmitFormRegister.bind(this);
        
        this.state = {};
    }
    handleChangeFormRegister(event){
        if(event.target.name === 'subscribe'){
            this.setState({
                [event.target.name] : event.target.checked
            })
        }else{
            this.setState({
                [event.target.name] : event.target.value
            });
        }
    }
    handleSubmitFormRegister(event){
        event.preventDefault();
        event.stopPropagation();
        if(Object.values(this.state).length === 5 && this.state.password.length > 7 && this.state.password === this.state.confirmPassword){
            RegisterUser(this.state).then(result => {
                if(result.success === true){
                    this.props.handleSetLogIn(result.is_registered);
                    return this.props.handleSetShowSnackBarLogin(true, true, 'THE ACCOUNT HAS BEEN SUCCESSFULLY CREATED. YOU HAVE BEEN LOGGED IN', false);
                }else{
                    return this.props.handleSetShowSnackBarLogin(false, true, result.message, true);
                }
            })
        }
        else{
            return this.props.handleSetShowSnackBarLogin(false, true, 'NOT ALL FIELDS CORRECTLY COMPLETED', true);
        }
    }
    render(){
        return(
            <Container className="container-modal-params">
                <Form onSubmit={this.handleSubmitFormRegister}>
                    <Row className="h-100 row-params">
                        <Col lg={6} md={6} xs={6}>
                            <Form.Group as={Row} className="mb-2" controlId="login">
                                <Form.Label column md="5" className="label-params p-0">
                                    LOGIN
                                </Form.Label>
                                <Col md="7" className="form-control-input">
                                    <Form.Control type="text" autoComplete="on" name="login" className="input-form-params" maxLength={50} value={this.state.login || ''} placeholder="Your login" onChange={this.handleChangeFormRegister}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2" controlId="name">
                                <Form.Label column md="5" className="label-params p-0">
                                    NAME
                                </Form.Label>
                                <Col md="7" className="form-control-input">
                                    <Form.Control type="text" autoComplete="on" name="name" className="input-form-params" maxLength={50} value={this.state.name || ''} placeholder="Your name" onChange={this.handleChangeFormRegister}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2" controlId="email">
                                <Form.Label column md="5" className="label-params p-0">
                                    E-MAIL
                                </Form.Label>
                                <Col md="7" className="form-control-input">
                                    <Form.Control type="email" autoComplete="on" name="email" className="input-form-params" maxLength={50} value={this.state.email || ''} placeholder="Email Address" onChange={this.handleChangeFormRegister}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2" controlId="password">
                                <Form.Label column md="5" className="label-params p-0">
                                    PASSWORD
                                </Form.Label>
                                <Col md="7" className="form-control-input">
                                    <Form.Control type="password" autoComplete="new-password" name="password" className="input-form-params" minLength={8} maxLength={50} value={this.state.password || ''} placeholder="8 digits password" onChange={this.handleChangeFormRegister}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2" controlId="confirmPassword">
                                <Form.Label column md="5" className="label-params p-0">
                                    CONFIRM PASSWORD
                                </Form.Label>
                                <Col md="7" className="form-control-input">
                                    <Form.Control type="password" autoComplete="new-password" name="confirmPassword" className="input-form-params" minLength={8} maxLength={50} value={this.state.confirmPassword || ''} placeholder="Confirm password" onChange={this.handleChangeFormRegister}/>
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Button type="submit" variant="danger" className="submit-button-form float-right">SUBMIT</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Container>
        )
    }
}
export default FormRegister;