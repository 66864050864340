import React from 'react';
import {Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as SelectIcon } from './icons/SelectIcon.svg';
import { ReactComponent as TextIcon } from './icons/TextIcon.svg';
import { ReactComponent as PanIcon } from './icons/PanIcon.svg';
import { ReactComponent as RectangleIcon } from './icons/RectangleIcon.svg';
import { ReactComponent as CircleIcon } from './icons/CircleIcon.svg';
import { ReactComponent as PencilIcon } from './icons/PencilIcon.svg';
import { ReactComponent as LineIcon } from './icons/LineIcon.svg';
import { ReactComponent as UndoIcon } from './icons/UndoIcon.svg';
import { ReactComponent as RedoIcon } from './icons/RedoIcon.svg';
import { ReactComponent as CloseIcon } from './icons/CloseIcon.svg';
import {Tools} from 'react-sketch2';

class ToolsButtons extends React.Component{
    constructor(props){
        super(props);
        
        this.handleActiveButton = this.handleActiveButton.bind(this);
        this.handleCloseInputText = this.handleCloseInputText.bind(this);
        this.handleChangeTool = this.handleChangeTool.bind(this);
        this.handleKeyAddText = this.handleKeyAddText.bind(this);
        this.handleLineColor = this.handleLineColor.bind(this);
        this.handleSetInputText = this.handleSetInputText.bind(this);
        this.handleSetText = this.handleSetText.bind(this);
        
        this.state = {
            currentText:'',
            toolTip:{
                trigger : ['hover', 'hover'],
                placement : 'right',
                show : 850,
                hide : 100
            }
        };
        
        this.refInputText = React.createRef([]);
    }
    componentDidMount(){
        window.addEventListener('keydown', this.handleKeyAddText);
    }
    handleKeyAddText(event){
        if(event.keyCode === 13){
            event.preventDefault();
            event.stopPropagation();
            this.props.handleAddText(this.state.currentText);
            return this.handleChangeTool(Tools.Select);
        }
    }
    handleActiveButton(tool){
        if(this.props.actualShape === tool){
            return 'active';
        }
    }
    handleCloseInputText(){
        return this.setState({
            checkInputText:false
        });
    }
    handleChangeTool(tool){
        if(tool !== Tools.addText){
            this.setState({
                checkInputText:false
            });
        }
       return this.props.handleChangeTool(tool);
    }
    handleLineColor(color){
        return this.props.handleLineColor(color.hex);
    }
    handleSetInputText(){
        this.setState({
            checkInputText : true,
            inputTextTop : this.refInputText.current.offsetTop,
            inputTextLeft : this.refInputText.current.clientWidth + this.refInputText.current.offsetLeft,
            inputTextHeight : this.refInputText.current.clientHeight
        });
        return this.handleChangeTool(Tools.addText);
    }
    handleSetText(event){
        return this.setState({
            currentText : event.target.value
        });
    }
    handleRedoStage(){
        return this.props.handleRedo();
    }
    handleUndoStage(){
        return this.props.handleUndo();
    }
    render(){
        let inputText, inputStyleDivText, inputStyleCloseIcon;
        if(this.state.checkInputText === true){
            inputStyleDivText = {
                zIndex : '99',
                position:'absolute',
                display:'flex',
                top : this.state.inputTextTop+'px',
                left : this.state.inputTextLeft+'px',
                height : this.state.inputTextHeight+'px'
            };
            inputStyleCloseIcon = {
                width : '1em', 
                fill : '#000000', 
                position : 'absolute',
                top : '1%',
                left : '1%'
            };
            inputText = 
                <div style={inputStyleDivText}>
                    <input type="text" className="input-add-text" placeholder="type something here" onChange={this.handleSetText}/>
                        <IconButton onClick={this.handleCloseInputText}>
                            <CloseIcon style={inputStyleCloseIcon}/>
                        </IconButton>
                </div>;
        }
        return(
            <Col className="col-tools-bar mt-5">
                <div className="tools-bar">
                    {inputText}
                    <li className="item-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger}
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Select Tool</Tooltip>}
                        >
                                <IconButton className="item-tool-button" active={this.handleActiveButton(Tools.Select)} onClick={()=>this.handleChangeTool(Tools.Select)}>
                                    <SelectIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                    <li className="item-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger}
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Text</Tooltip>}
                        >
                                <IconButton className="item-tool-button" ref={ref => this.refInputText.current = ref} active={this.handleActiveButton(Tools.addText)} onClick={this.handleSetInputText}>
                                    <TextIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                    <li className="item-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger}
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Hand Tool</Tooltip>}
                        >
                                <IconButton className="item-tool-button" active={this.handleActiveButton(Tools.Pan)} onClick={()=>this.handleChangeTool(Tools.Pan)}>
                                    <PanIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                    <li className="item-tool-bar separate-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger}
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Rectangle</Tooltip>}
                        >
                                <IconButton className="item-tool-button" active={this.handleActiveButton(Tools.Rectangle)} onClick={()=>this.handleChangeTool(Tools.Rectangle)}>
                                    <RectangleIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                    <li className="item-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger} 
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Circle</Tooltip>}
                        >
                                <IconButton className="item-tool-button" active={this.handleActiveButton(Tools.Circle)} onClick={()=>this.handleChangeTool(Tools.Circle)}>
                                    <CircleIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                    <li className="item-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger} 
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Pencil</Tooltip>}
                        >
                                <IconButton className="item-tool-button" active={this.handleActiveButton(Tools.Pencil)} onClick={()=>this.handleChangeTool(Tools.Pencil)}>
                                    <PencilIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                    <li className="item-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger}
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Line</Tooltip>}
                        >
                                <IconButton className="item-tool-button" active={this.handleActiveButton(Tools.Line)} onClick={()=>this.handleChangeTool(Tools.Line)}>
                                    <LineIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                    <li className="item-tool-bar separate-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger}
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Undo</Tooltip>}
                        >
                                <IconButton className="item-tool-button" disabled={!this.props.canUndo} onClick={()=>this.handleUndoStage()}>
                                    <UndoIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                    <li className="item-tool-bar">
                        <OverlayTrigger
                            trigger={this.state.toolTip.trigger}
                            placement={this.state.toolTip.placement}
                            delay={{show: this.state.toolTip.show, hide: this.state.toolTip.hide}}
                            overlay={<Tooltip>Redo</Tooltip>}
                        >
                                <IconButton className="item-tool-button" disabled={!this.props.canRedo} onClick={()=>this.handleRedoStage()}>
                                    <RedoIcon/>
                                </IconButton>
                        </OverlayTrigger>
                    </li>
                </div>
        </Col>
        )
    }
}
export default ToolsButtons;