import React from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {PasswordCodeRecovery, PasswordRecovery} from './ModelStage';
import SnackBarMessage from './SnackBarMessage';

class FormPasswordRecovery extends React.Component{
    constructor(props) {
        super(props);
        
        this.handleChangeFormRecoveryPassword = this.handleChangeFormRecoveryPassword.bind(this);
        this.handleSetCloseSnackBarResetCodeAlert = this.handleSetCloseSnackBarResetCodeAlert.bind(this);
        this.handleSetShowSnackBarResetCodeAlert = this.handleSetShowSnackBarResetCodeAlert.bind(this);
        this.handleSubmitFormCodeRecoveryPassword = this.handleSubmitFormCodeRecoveryPassword.bind(this);
        this.handleSubmitFormRecoveryPassword = this.handleSubmitFormRecoveryPassword.bind(this);
        
        this.state = {
            email : '',
            code : '',
            newPassword : '',
            messageResetCodeAlert : '',
            confirmNewPassword : '',
            showResetCodeAlert : false,
            successResetCodeAlert : false
        };
    }
    handleAlertSendedCodeRecoveryPassword(){
        return this.handleSetShowSnackBarResetCodeAlert(true, true, "WE'VE ALREADY SENT YOU INSTRUCTIONS ON HOW TO RECOVER YOUR PASSWORD, CHECK YOUR MAIL");
    }
    handleChangeFormRecoveryPassword(event){
        return this.setState({
            [event.target.name] : event.target.value
        });
    }
    componentDidMount(){
        if(this.props.isSendCode === true && this.props.countRecoveryPassword > 0){
            return this.handleAlertSendedCodeRecoveryPassword();
        }
    }
    handleSetShowSnackBarResetCodeAlert(showResetCodeAlert, successResetCodeAlert, messageResetCodeAlert){
        return this.setState({
            messageResetCodeAlert : messageResetCodeAlert,
            showResetCodeAlert : showResetCodeAlert,
            successResetCodeAlert : successResetCodeAlert
        });
    }
    handleSetCloseSnackBarResetCodeAlert(){
        return this.setState({
            showResetCodeAlert : false
        });
    }
    handleSubmitFormCodeRecoveryPassword(event){
        event.preventDefault();
        event.stopPropagation();
        PasswordCodeRecovery(this.state.email).then(result => {
            if(result.success === true){
                this.props.handleSetSendCode(true);
                this.props.handleSetLastEmail(this.state.email);
                return this.props.handleSetShowSnackBarLogin(true, true, 'CHECK YOUR EMAIL. WE HAVE SENT YOU A CODE TO CONFIRM YOUR PASSWORD RESET', false);
            }else{
                return this.props.handleSetShowSnackBarLogin(false, true, result.message, true);
            }
        })
    }
    handleSubmitFormRecoveryPassword(event){
        event.preventDefault();
        event.stopPropagation();
        if(this.state.code.length > 0 && this.state.newPassword.length > 7 && this.state.newPassword === this.state.confirmNewPassword){
            PasswordRecovery(this.state.code, this.props.lastEmail, this.state.newPassword).then(result => {
                if(result.success === true){
                    this.props.handleSetSendCode(false);
                    return this.props.handleSetShowSnackBarLogin(true, true, 'YOU CAN LOGIN WITH NEW PASSWORD', false);
                }
                else{
                    return this.props.handleSetShowSnackBarLogin(false, true, result.message, true);
                }
            })
        }else{
            return this.props.handleSetShowSnackBarLogin(false, true, 'NOT ALL FIELDS CORRECTLY COMPLETED', true);
        }
    }
    render(){
        return(
            <Container className="container-modal-params">
                {this.props.isSendCode === false ?
                    <>
                        <Form onSubmit={this.handleSubmitFormCodeRecoveryPassword}>
                            <Row className="h-100 row-params">
                                <Col lg={6} md={6} xs={6}>
                                    <Form.Group as={Row} className="mb-2" controlId="email">
                                        <Form.Label column md="5" className="label-params p-0">
                                            E-MAIL
                                        </Form.Label>
                                        <Col md="7" className="form-control-input">
                                            <Form.Control type="email" autoComplete="on" name="email" className="input-form-params" maxLength={50} value={this.state.email || ''}  placeholder="Email Address" onChange={this.handleChangeFormRecoveryPassword}/>
                                         </Col>
                                    </Form.Group>
                                    <Row>
                                        <Col>
                                            <Button type="submit" variant="danger" className="submit-button-form float-right">GET CODE</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </>
                :
                    <>
                        <SnackBarMessage
                            show={this.state.showResetCodeAlert}
                            success={this.state.successResetCodeAlert}
                            message={this.state.messageResetCodeAlert}
                            handleSetCloseSnackBar={this.handleSetCloseSnackBarResetCodeAlert}
                        />
                        <Form onSubmit={this.handleSubmitFormRecoveryPassword}>
                            <Row className="h-100 row-params">
                                <Col lg={6} md={6} xs={6}>
                                    <Form.Group as={Row} className="mb-2" controlId="email">
                                        <Form.Label column md="5" className="label-params p-0">
                                            E-MAIL
                                        </Form.Label>
                                        <Col md="7" className="form-control-input">
                                            <Form.Control type="email" autoComplete="on" name="email" className="input-form-params" value={this.props.lastEmail || ''}  placeholder="Email Address" disabled={true}/>
                                         </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2" controlId="code">
                                        <Form.Label column md="5" className="label-params p-0">
                                            CODE
                                        </Form.Label>
                                        <Col md="7" className="form-control-input">
                                            <Form.Control type="text" autoComplete="on" name="code" className="input-form-params" value={this.state.code || ''} placeholder="recovery code" onChange={this.handleChangeFormRecoveryPassword}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2" controlId="newPassword">
                                        <Form.Label column md="5" className="label-params p-0">
                                            PASSWORD
                                        </Form.Label>
                                        <Col md="7" className="form-control-input">
                                            <Form.Control type="password" autoComplete="new-password" name="newPassword" className="input-form-params" minLength={8} maxLength={50} value={this.state.newPassword || ''} placeholder="8 digits password" onChange={this.handleChangeFormRecoveryPassword}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2" controlId="confirmNewPassword">
                                        <Form.Label column md="5" className="label-params p-0">
                                            CONFIRM PASSWORD
                                        </Form.Label>
                                        <Col md="7" className="form-control-input">
                                            <Form.Control type="password" autoComplete="new-password" name="confirmNewPassword" className="input-form-params" minLength={8} maxLength={50} value={this.state.confirmNewPassword || ''} placeholder="Confirm password" onChange={this.handleChangeFormRecoveryPassword}/>
                                        </Col>
                                    </Form.Group>
                                    <Row>
                                        <Col>
                                            <Button type="submit" variant="danger" className="submit-button-form float-right">SAVE</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
            </Container>
        )
    }
}
export default FormPasswordRecovery;